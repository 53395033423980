import localforage from "localforage";
import React, { useContext, useEffect, useRef, useState } from "react";
import Koala from "../../assets/images/koala/koala-spinner.gif";
import { BoardContext } from "../../context/BoardsContext";
import { ClientType } from "../../types";
import "../DashboardContainer/landingPage.scss";
import Board from "./board.component";
import "./boardsContainer.scss";

type BoardsContainerProps = {
  boardId: string | null;
  showBulk: boolean;
};

const BoardsContainer: React.FC<BoardsContainerProps> = ({ boardId }) => {
  const [lastBoardIndex, setLastBoardIndex] = useState(12);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  // const [boards, setBoards] = useState<AllBoards>([]);1111114
  const [loadingBoards, setLoadingBoards] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const boards = useContext(BoardContext);
  const [clientDetails, setClientDetails] = useState<ClientType[] | string>();
  const [filters, setFilters] = useState<string>("all");
  const [filterBoards, setFilterBoards] = useState(boards?.allBoards);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    console.log("useEffect running");
    if (loadMoreRef.current) {
      console.log("loadMoreRef:", loadMoreRef.current);
      const observer = new IntersectionObserver(
        ([entry]) => {
          console.log("Observer entry:", entry);
          if (entry.isIntersecting) {
            console.log("Intersecting:", entry.target);
            setLastBoardIndex((prevState) => prevState + 4);
          }
        },
        { threshold: 1 }
      );
      observer.observe(loadMoreRef.current);
      return () => {
        console.log("Disconnecting observer");
        observer.disconnect();
      };
    } else {
      console.log("loadMoreRef is not attached to a DOM element");
    }
  }, [loadingBoards]); // loadingBoards is the issue

  useEffect(() => {
    //Fetch client details from local storage
    const fetchClient = async () => {
      try {
        const localClientDetails = await localforage.getItem("allClients");
        if (
          localClientDetails !== null &&
          typeof localClientDetails === "string"
        ) {
          try {
            const clientDetails = JSON.parse(localClientDetails);
            setClientDetails(clientDetails.clients);
          } catch (error) {
            console.log("Error parsing client details:", error);
            setClientDetails("Error parsing client details");
          }
        } else {
          console.log("Client details not found in local storage");
        }
      } catch (error) {
        console.log("Error fetching client details:", error);
      }
    };
    fetchClient();
  }, []);

  //useEffect for filters
  useEffect(() => {
    if (boards?.allBoards === undefined) {
      return;
    }

    let filteredBoards = boards?.allBoards;

    // Apply filter if not 'all'
    if (filters !== "all") {
      filteredBoards = filteredBoards.filter(
        (board) => board.clientID === filters
      );
    }

    // Apply search
    if (search !== "") {
      filteredBoards = filteredBoards.filter((board) =>
        board.title.toLowerCase().includes(search.toLowerCase())
      );
    }

    setFilterBoards(filteredBoards);
  }, [filters, search, boards?.allBoards]);

  return (
    <>
      {boards && boards.isBoardsLoaded === false ? (
        <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
          <img
            src={Koala}
            alt="Loading..."
            style={{ width: "150px", height: "150px" }}
          />
        </div>
      ) : loadingError ? (
        <div>
          <p>{loadingError}</p>
        </div>
      ) : !boards ||
        (boards.allBoards &&
          boards.allBoards.length === 0 &&
          boards.isBoardsLoaded) ? (
        <div className="no_boards_container">
          <p className="no_boards">No boards found</p>
        </div>
      ) : (
        <>
          <div className="filter-container">
            <p className="filter-title">Filter Options :</p>
            <div className="filter-options">
              <input
                type="text"
                placeholder="Search..."
                className="uk-input search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <select
                name="filter"
                className="uk-select project-filter"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === "0") {
                    setFilters("all");
                  } else {
                    setFilters(value);
                  }
                }}
              >
                <option value="0">All Projects</option>
                {clientDetails &&
                clientDetails.length > 0 &&
                typeof clientDetails !== "string" ? (
                  clientDetails.map((clientInfo) => (
                    <option
                      key={clientInfo.client_id}
                      value={clientInfo.client_id}
                    >
                      {clientInfo.name}
                    </option>
                  ))
                ) : (
                  <option value="0">No clients found</option>
                )}
              </select>
            </div>
          </div>
          <div
            id="boards_grid"
            className="uk-grid uk-child-width-1-2 uk-child-width-1-3@m uk-child-width-1-4@l"
          >
            {filterBoards && filterBoards.length > 0 ? (
              filterBoards.map((board) => (
                <div key={board.id} className="board_div_container">
                  <Board
                    id={board.id}
                    title={board.title}
                    coverImage={board.coverImage}
                    active={board.id === boardId}
                    clientID={board.clientID}
                  ></Board>
                </div>
              ))
            ) : (
              <div className="uk-width-1-1 search_result_photo_container ">
                <div id="no_images_container">
                  <div className="no_image">
                    <div id="no_image_div">No related boards found</div>
                  </div>
                </div>
              </div>
            )}
            <div ref={loadMoreRef}></div>
          </div>
        </>
      )}
    </>
  );
};

export default BoardsContainer;
