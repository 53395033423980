import Koala from "../../assets/images/koala/koala-spinner.gif";

const LoginLoading = () => {
  return (
    <div id="page_logging_in">
      <div className="uk-container uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
        <div
          id="logging_in_card"
          className="uk-card uk-card-default uk-width-2xlarge uk-box-shadow-large"
        >
          <div className="uk-card-body">
            <h3 className="uk-card-title uk-text-center">
              Smart Image Management{" "}
            </h3>
            <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
              <img
                src={Koala}
                alt="Loading..."
                style={{ width: "150px", height: "150px" }}
              />
              <h1>Logging In</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLoading;
