import React from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as ShareIcon } from "../../assets/images/icons/share.svg";
import placeholderImage from "../../assets/images/koala/KoalaTemp.png";
import { boardInvite } from "../../models/board.model";
import "./board.scss";

interface BoardProps {
  id: string;
  title: string;
  coverImage: string;
  clientID: string;
  active: boolean;
}

const Board: React.FC<BoardProps> = ({
  id,
  title,
  coverImage,
  active,
  clientID,
}) => {
  const navigate = useNavigate();

  const shareBoard = async (e: React.MouseEvent<SVGSVGElement>, id: string) => {
    e.stopPropagation();
    Swal.fire({
      title: "Sharing board",
      text: `Share this board with others by adding their email address below. If you want to share with multiple people, separate their email addresses with a comma.`,
      input: "email",
      inputPlaceholder: "Email address",
      confirmButtonText: "Send invitation",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
        //Email regex
        const regex = /\S+@\S+\.\S+/;
        if (value.includes(",")) {
          const emails = value.split(",");
          for (let email of emails) {
            if (!regex.test(email.trim())) {
              return "Please enter a valid email address!";
            }
          }
        } else {
          if (!regex.test(value)) {
            return "Please enter a valid email address!";
          }
        }
        return null;
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Sharing board",
          html: "Please wait...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const sendInvitation = await boardInvite(parseInt(id), [result.value]);

        Swal.close(); // Close loading Swal

        if (sendInvitation === "success") {
          Swal.fire(
            "Board shared",
            "You've successfully shared the board",
            "success"
          );
        } else {
          Swal.fire(
            "Error sharing board",
            "An error occurred while sharing the board. Please try again.",
            "error"
          );
        }
      }
    });
  };

  return (
    <div
      id={`board_${id}`}
      className="uk-card uk-card-default uk-card-small uk-margin-medium-bottom"
      style={{ backgroundImage: `url(${coverImage || placeholderImage})` }}
      onClick={() => navigate(`/${clientID}/board/${id}`)}
    >
      <div className="uk-card-footer">
        <span className="footer_title">{title}</span>
        <ShareIcon className="share-icon" onClick={(e) => shareBoard(e, id)} />
      </div>
    </div>
  );
};

export default Board;
