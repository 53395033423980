import Swal from "sweetalert2";
import { streamableApiFetcher } from "../services/API.service";
import {
  Attribute,
  AttributeName,
  AttributeValue,
  FilterType,
  ImageCode,
} from "../types";

//Example Data
// {
//  "response": {
//   "2323" : {
//     "Colour" : {
//       "Red": ["2323", "3223", "34"],
//       "Blue": ["232", "223", "4"],
//       "Green": ["233", "323", "34"]
//     }
//   },
//   "123" : {
//     "Size" : {
//       "Tall": ["34", "3423", "23"],
//       "Short": ["34", "23", "23"],
//       "Medium": ["34", "23", "23"]
//     }
//   }
// },
// "updated_at": 123456789
// }
//===============================================================================
//===========================Validation Functions ===============================
// Helper function to check if a variable is an object (and not null)
function isImageCode(data: unknown): data is ImageCode {
  if (!Array.isArray(data)) {
    console.log("Failed isImageCode validation", data);
    return false;
  }

  for (const item of data) {
    if (typeof item !== "string") {
      console.log("Failed isImageCode validation", data);
      return false;
    }
  }

  return true;
}

function isAttributeValue(data: unknown): data is AttributeValue {
  if (!isObject(data)) return false;

  for (const value of Object.values(data)) {
    if (!isImageCode(value)) {
      console.log("Failed isAttributeValue validation", data);
      return false;
    }
  }

  return true;
}

function isAttributeName(data: unknown): data is AttributeName {
  if (!isObject(data)) return false;

  for (const value of Object.values(data)) {
    if (!isAttributeValue(value)) {
      console.log("Failed isAttributeName validation", data);
      return false;
    }
  }

  return true;
}
//2.
function isAttribute(data: unknown): data is Attribute {
  if (!isObject(data)) return false;

  for (const value of Object.values(data)) {
    if (!isAttributeName(value)) {
      console.log("Failed isAttribute validation", data);
      return false;
    }
  }

  return true;
}
//1.Checks if the response is an object
function isFilterType(data: unknown): data is FilterType {
  if (!isObject(data)) return false;
  const result = isAttribute(data.response);
  if (!result) console.log("Failed isFilterType validation", data);
  return result;
}
//Checks if it is an object
function isObject(data: unknown): data is Record<string, unknown> {
  return typeof data === "object" && data !== null;
}

function isValidDate(data: unknown): data is { updated_at: number } {
  const dataAsDateObject = data as Partial<{ updated_at: number }>;
  const result =
    isObject(data) && typeof dataAsDateObject.updated_at === "number";
  if (!result)
    console.log("Failed isValidDate validation", dataAsDateObject.updated_at);
  return result;
}

//Main function to check response as well as updated_at
function isValidFilterType(data: unknown): data is FilterType {
  if (!isObject(data)) {
    console.log("Data is not an object");
    return false;
  }
  // Flow the order of the Functions
  if (!isFilterType(data)) {
    console.log("Data.response is not an object");
    return false;
  }

  //Check if updated_at is a number
  if (!isValidDate(data)) {
    console.log("Data.updated_at is not a number");
    return false;
  }
  return true;
}

export async function fetchFilters(
  selectType: string,
  clientID: string,
  boardID: string | null,
  abortSignal: AbortSignal
): Promise<FilterType | string> {
  console.log("Fetching filters");

  try {
    const response = await streamableApiFetcher<unknown>(
      "/config/preCache",
      "POST",
      {
        body: {
          clientID,
          boardID,
          selectType,
        },
      },
      abortSignal
    );

    if (response.status === 200 && response.data) {
      //response.data.response exists, but not on type unknown, how can i make it recognise it

      if (isValidFilterType(response.data)) {
        return {
          statusCode: 200,
          response: response.data.response,
          updated_at: response.data.updated_at,
        };
      } else {
        console.log("Incorrect data type");
        return "Failed to validate Filters";
      }
    } else if (response.status === 204) {
      return {
        statusCode: 204,
        response: {},
        updated_at: Date.now() / 1000,
      };
    } else if (response.status === 206) {
      // Data is still being built, acknowledge it but don't process data
      console.log("Pre-cache is still being built.");
      return {
        statusCode: 206,
        response: {}, // No data yet
        updated_at: Date.now() / 1000, // Optional: Add a timestamp if needed
      };
    } else if (response.status === 401) {
      return "Unauthorized access";
    } else {
      return "Failed to get data from server";
    }
  } catch (error) {
    console.log("Error fetching filters", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong",
    });
    return "Error fetching filters";
  }
}

function isValidData(data: unknown): data is { updated_at: number } {
  return (
    typeof data === "object" &&
    data !== null &&
    "updated_at" in data &&
    typeof (data as { updated_at: unknown }).updated_at === "number"
  );
}

export async function getFiltersChecker(
  clientID: string,
  boardID: string | null
): Promise<string | number> {
  console.log("Checking for updates");
  try {
    const response = await streamableApiFetcher<unknown>(
      "/config/preCache/refresh",
      "POST",
      {
        body: {
          clientID,
          boardID,
        },
      }
    );
    if (response.status === 200 && response.data) {
      if (isValidData(response.data)) {
        return response.data.updated_at;
      } else {
        console.log("Failed to validate data");
        return "Failed to validate date";
      }
    } else {
      console.log("Failed to get status");
      return "Failed to get status";
    }
  } catch (error) {
    console.warn("Error fetching filters", error);
    return "Error fetching filters";
  }
}
