import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import koala from "../../assets/images/koala/KoalaTemp.webp";
import "./searchGPT.scss";

export type SearchGPTPageProps = {
  onThemeChange: () => void;
};

const SearchGPTPage: React.FC<SearchGPTPageProps> = ({ onThemeChange }) => {
  const { searchType, searchTags } = useParams();

  // states
  const [boardId, setBoardId] = useState<string | null>(null);
  // Sets the word and starts as searching...
  const [randomWord, setRandomWord] = useState<string>("Searching...");
  // Array of words that will be randomed
  const wordsArray = [
    "Thinking...",
    "Searching...",
    "Compiling...",
    "Analyzing...",
    "Retrieving...",
    "Indexing...",
    "Filtering...",
    "Sorting...",
  ];

  const navigate = useNavigate();

  const handleBoardClick = (id: string) => {
    setBoardId(id);
  };

  // This will randomize the words in the array
  useEffect(() => {
    // This variable is used to store the index of the previously selected word.
    // It starts with -1 as an initial value because array indices start from 0.
    let previousWordIndex = -1;
    let cycleCount = 0; //for demo redirect after 3 cycles
    // Runs a callback function repeatedly with a fixed time delay.
    const interval = setInterval(() => {
      // This variable is used to store the randomly generated index
      let randomIndex;

      do {
        // Randomize the word in the array
        randomIndex = Math.floor(Math.random() * wordsArray.length);
        // Generates a new random index as long as it is equal to the previousWordIndex. It ensures that the same word is not selected twice in a row.
      } while (randomIndex === previousWordIndex);

      // This variable is used to store the randomly selected word from the array
      const randomWord = wordsArray[randomIndex];
      setRandomWord(randomWord);
      // Set the previousWordIndex to the randomIndex
      previousWordIndex = randomIndex;
      console.log(searchTags, searchType);

      //for demo redirect after 3 cycles
      cycleCount++;
      if (cycleCount === 3) {
        navigate(`/result/${searchType}/${searchTags}`);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div id="searchKoalaMain" className="uk-grid">
      {/* This calls the header element from the infrastructure folder */}
      <div className="uk-width-1-1">
        {/* <Header
          onThemeChange={onThemeChange}
          // showBulk={showBulk}
        /> */}
      </div>
      {/* This calls the sidebar element from the infrastructure folder */}
      <div className="uk-width-1-3@s uk-width-1-5@m">{/* <Sidebar /> */}</div>
      {/* This creates the image and random word creation */}
      <div className="koalaSection uk-width-2-3@s uk-width-4-5@m">
        <div className="imageContainer">
          <img className="koalaImage" src={koala} alt="MDI Koala" />
        </div>
        <div>
          <h1 className="words">{randomWord}</h1>
        </div>
      </div>
    </div>
  );
};
export default SearchGPTPage;
