import jwtDecode from "jwt-decode";
import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Swal from "sweetalert2";
import { BoardsProvider } from "../context/BoardsContext";
import {
  getTokensLocalStorage,
  isTokenAboutToExpire,
  isTokenExpired,
  requestAndSetTokens,
} from "./tokenHandler.util";

interface ProtectedRouteProps {
  // children: ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCheckComplete, setIsCheckComplete] = useState(false);

  async function checkUserTokens() {
    // Get tokens from local storage
    const { authToken, refreshToken } = await getTokensLocalStorage();
    // if auth-token exists and is about to expire, requestandsettokens
    if (authToken && refreshToken && isTokenAboutToExpire(authToken)) {
      await requestAndSetTokens({ refreshToken: refreshToken });
    }

    // If the refresh token is expired or invalid, then log the user out
    if (
      !refreshToken ||
      !authToken ||
      refreshToken === "undefined" ||
      isTokenExpired(refreshToken)
    ) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      isTokenExpired(authToken);
    }

    // checkUserTokens has completed
    setIsCheckComplete(true);
  }
  //Toast Message
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    width: "100%", // Adjust width to 'auto' or any specific value
    padding: "1rem", // Optional: Adjust padding if needed
    confirmButtonColor: "#3085d6",
    customClass: {
      popup: "custom-swal",
    },
    // timer: 2000,
  });

  useEffect(() => {
    // check if user is valid in every 5 seconds
    checkUserTokens();
    const interval = setInterval(() => {
      checkUserTokens();
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  type authDecoded = {
    email: string;
    firstName: string;
    lastName: string;
  };
  useEffect(() => {
    if (isCheckComplete) {
      //There is no profile for now
      const setLogRocketUser = async () => {
        const { authToken } = await getTokensLocalStorage();
        if (authToken) {
          const decodedToken: authDecoded = jwtDecode(authToken);

          LogRocket.identify(decodedToken.email, {
            name: decodedToken.firstName + " " + decodedToken.lastName,
            email: decodedToken.email,
          });
        }
      };
      setLogRocketUser();
    }
  }, [isCheckComplete]);

  // If the check is not complete, show a loading spinner
  if (!isCheckComplete) {
    return (
      <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
        {/* <img
          src={Koala}
          alt="Loading..."
          style={{ width: "150px", height: "150px" }}
        /> */}
        <div
          className="uk-spinner"
          uk-spinner="ratio: 2"
          style={{
            width: "auto",
            height: "auto",
            marginTop: "20px",
          }}
        ></div>
      </div>
    );
  }

  return (
    <>
      {isLoggedIn ? (
        <BoardsProvider>
          <Outlet />
        </BoardsProvider>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default ProtectedRoute;
