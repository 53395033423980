import React from "react";
import ProfileContainer from "../../components/Profile/profileContainer.component";
import Header from "../../infrastructure/Header/header.component";
import "./profile.scss";

export type ProfilePageProps = {
  onThemeChange: () => void;
};

const ProfilePage: React.FC<ProfilePageProps> = ({ onThemeChange }) => {
  return (
    <div id="profile" className="uk-grid">
      {/* This calls the header element from the infrastructure folder */}
      <div className="uk-width-1-1">
        <Header onThemeChange={onThemeChange} selectType="none" />
      </div>
      {/* This calls the sidebar element from the infrastructure folder */}
      <div className="uk-width-1-3@s uk-width-1-5@m">{/* <Sidebar /> */}</div>
      {/* This calls the photos container element from the components folder */}
      <div className="uk-width-2-3@s uk-width-4-5@m inner_container uk-child-width-1-1">
        <ProfileContainer />
      </div>
    </div>
  );
};

export default ProfilePage;
