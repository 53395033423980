import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Koala from "../../assets/images/koala/koala-spinner.gif";
import ClientsContainer from "../../components/Clients/clientsContainer.component";
import Header from "../../infrastructure/Header/header.component";
import { ClientType } from "../../types";
import { loadAllClients } from "../../utilities/loadClients.util";
import "./viewClients.scss";

// import "./viewClients.scss";

export type ViewClientsPageProps = {
  onThemeChange: () => void;
};

const ViewClientsPage: React.FC<ViewClientsPageProps> = ({ onThemeChange }) => {
  const [boardId, setBoardId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<ClientType[]>([]);

  // Show the bulk icon in the header depending on whether photos are selected in Photo Container
  const [showBulk, setShowBulk] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const getAllClients = async () => {
      let clients = await loadAllClients();
      if (typeof clients !== "string" && Array.isArray(clients.clients)) {
        setClients(clients.clients);
        setIsLoading(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: clients,
        });
        setIsLoading(false);
        setClients([]);
      }
    };
    getAllClients();
  }, []);

  const handleBoardClick = (id: string) => {
    setBoardId(id);
  };

  return (
    <div id="dashboard" className="uk-grid">
      {/* This calls the header element from the infrastructure folder */}
      <div className="uk-width-1-1">
        <Header
          onThemeChange={onThemeChange}
          showBulk={showBulk}
          selectType="none"
        />
      </div>
      {/* This calls the sidebar element from the infrastructure folder */}
      <div className="uk-width all-client-page">
        {isLoading ? (
          <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <img
              src={Koala}
              alt="Loading..."
              style={{ width: "150px", height: "150px" }}
            />
          </div>
        ) : (
          <ClientsContainer
            clients={clients}
            boardId={boardId}
            showBulk={showBulk}
            numberOfColumns={5}
            maxHeight={75}
            showPagination={true}
          />
        )}
      </div>
    </div>
  );
};

export default ViewClientsPage;
