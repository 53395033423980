import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import { AllBoards } from "../types";

function isAllBoards(obj: any): obj is AllBoards {
  try {
    if (typeof obj !== "object") {
      return false;
    }

    const validID = typeof obj.id === "string";
    const validTitle = typeof obj.title === "string";
    const validCoverImage = typeof obj.coverImage === "string";
    const validActive = typeof obj.active === "boolean";
    const validClientID = typeof obj.clientID === "string";
    const validClient = typeof obj.client === "boolean";

    const AllValid =
      validID &&
      validTitle &&
      validCoverImage &&
      validActive &&
      validClientID &&
      validClient;

    if (!AllValid) {
      console.log(obj);
      let errors = [];

      if (!validID) {
        if (!obj.hasOwnProperty("id")) {
          errors.push("missing id");
          let displayMessage = `<strong>Please contact support</strong><br><br>`;

          Swal.fire({
            icon: "error",
            title: `Invalid Board`,
            html: displayMessage + errors.join("<br>"),
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
          });
          return false;
        } else {
          errors.push(`Invalid type for id`);
        }
      }

      if (!validTitle) {
        if (!obj.hasOwnProperty("title")) {
          errors.push("missing title");
        } else {
          errors.push(`Invalid type for title`);
        }
      }

      if (!validCoverImage) {
        if (!obj.hasOwnProperty("coverImage")) {
          errors.push("missing coverImage");
        } else {
          errors.push(`Invalid type for coverImage`);
        }
      }

      if (!validActive) {
        if (!obj.hasOwnProperty("active")) {
          errors.push("missing active");
        } else {
          errors.push(`Invalid type for active`);
        }
      }

      if (!validClientID) {
        if (!obj.hasOwnProperty("clientID")) {
          errors.push("missing clientID");
        } else {
          errors.push(`Invalid type for clientID`);
        }
      }

      if (!validClient) {
        if (!obj.hasOwnProperty("client")) {
          errors.push("missing client");
        } else {
          errors.push(`Invalid type for client`);
        }
      }

      console.log(`Invalid imageID: ${obj.id}`);

      errors.forEach((error) => {
        // remove first empty element
        if (error !== "") {
          console.log(error);
        }
      });

      let displayMessage = `<strong>Please contact support</strong><br><br>`;
      displayMessage += `BoardID: ${obj.id}<br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid Board`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
    }

    return AllValid;
  } catch (error) {
    console.error("An error occurred while fetching boards:", error);
    return false;
  }
}

export type CreateBoard = {
  message: {
    message: string;
    timestamp: string;
  };
  boardID: number;
};

// create board
export async function createBoard(
  boardName: string,
  clientID: string,
  imageIDs?: string[]
): Promise<string | CreateBoard> {
  try {
    const response = await apiFetcher<any>("/board/create", "POST", {
      body: {
        imageBoards: { boardName: boardName, boardImages: imageIDs },
        clientID: clientID,
      },
    });

    console.log(response);

    if (response.status === 200 && response.data !== null) {
      console.log(response.data);
      return response.data as CreateBoard;
    }

    if (response.status === 400) {
      Swal.fire({
        icon: "error",
        title: `Data format is incorrect`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return "Data format is incorrect";
    }

    if (response.status === 404) {
      console.log("Route not found");
      return "Route not found";
    }

    if (response.status === 409) {
      return "Board name already exists. Please choose another name";
    }

    console.log("Failed to create board");
    return "Failed to create board";
  } catch (error) {
    console.error("An error occurred while creating board:", error);
    return "An error occurred while creating board";
  }
}

// update board name
export async function boardUpdate(
  boardID: string,
  boardName: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    const response = await apiFetcher<any>("/board/update", "POST", {
      body: {
        boardID: boardID,
        boardName: boardName,
      },
    });

    if (response.status === 200 && response.data !== null) {
      console.log(response.data);
      return {
        rStatus: "success",
        rMessage: "Board Name has been changed",
      };
    } else {
      console.log("An error occurred while updating the board name:");
      Swal.fire({
        icon: "error",
        title: `There was an error updating the board name`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return {
        rStatus: "error",
        rMessage: "Error has occurred updating board name",
      };
    }
  } catch (error) {
    console.error("An error occurred while updating the board name:", error);
    Swal.fire({
      icon: "error",
      title: `There was an error updating the board name`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
    });
    return {
      rStatus: "error",
      rMessage: "Error has occurred updating board name",
    };
  }
}

// Delete Board
export async function boardDelete(boardID: string): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    const response = await apiFetcher<any>("/board/delete", "POST", {
      body: {
        boardID: boardID,
      },
    });

    if (response.status === 200 && response.data !== null) {
      return {
        rStatus: "success",
        rMessage: "Board Has Been Deleted",
      };
    } else {
      console.log("An error occurred while attempting to delete the board:");
      Swal.fire({
        icon: "error",
        title: `There was an error deleting the board`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return {
        rStatus: "error",
        rMessage: "Error has occurred deleting the board",
      };
    }
  } catch (error) {
    console.error("An error occurred while deleting the board:", error);
    Swal.fire({
      icon: "error",
      title: `There was an error deleting the board`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
    });
    return {
      rStatus: "error",
      rMessage: "Error has occurred deleting the board",
    };
  }
}

export type AllBoardsResponse = {
  boards: AllBoards;
  expires_at: number;
};

//  get all boards
export async function getAllBoards(): Promise<AllBoardsResponse | string> {
  try {
    const response = await apiFetcher<unknown>(
      "/board/read/researcherID",
      "POST",
      {
        body: {},
      }
    );

    console.log(response.status);
    console.log(response.data);

    if (
      response.status === 200 &&
      response.data !== null &&
      typeof response.data === "object" &&
      "boards" in response.data &&
      response.data.boards !== null
    ) {
      console.log(response.data);

      if (!response.data) {
        console.log("No boards object found");
        return "No boards object found";
      }

      //------Positive responses------//
      if (!response.data || !Array.isArray(response.data.boards)) {
        console.log("Data is not an array");
        return "Data is not an array";
      }
      // Positive response
      if (response.data.boards.every((obj: unknown) => isAllBoards(obj))) {
        console.log("Valid board data received");

        if (
          !("expires_at" in response.data) ||
          typeof response.data.expires_at !== "number"
        ) {
          console.log("Invalid expires_at data received");
          return "Invalid expires_at data received";
        }

        return response.data as AllBoardsResponse;
      } else {
        console.log("Invalid image data received");
        return "Invalid board data received";
      }
    }

    if (response.status === 204) {
      console.log("No boards found");
      return { boards: [], expires_at: 0 } as unknown as AllBoardsResponse;
    }

    //-------Failed responses-------//
    if (response.status === 404) {
      console.log("Route not found");
      return "Route not found";
    } else {
      console.log("Failed to fetch all boards");
      return "Failed to fetch all boards";
    }
  } catch (error) {
    console.error("An error occurred while fetching boards:", error);
    return "An error occurred while fetching boards";
  }
}

//Share board
export async function boardInvite(
  boardID: number,
  emailArray: string[]
): Promise<string> {
  try {
    const response = await apiFetcher<any>("/board/share", "POST", {
      body: {
        boardID: boardID,
        emailArray: emailArray,
      },
    });

    if (response.status === 200) {
      return "success";
    } else if (response.status === 409) {
      return "Board name already exists. Please choose another name";
    } else if (response.status === 400) {
      return "Data format is incorrect";
    } else if (response.status === 404) {
      return "Route not found";
    } else {
      return "Failed to share board";
    }
  } catch (error) {
    console.error("An error occurred while sharing board:", error);
    return "An error occurred while sharing board";
  }
}

// Function to copy images to a board
export async function copyImages({
  imageIDs,
  currentBoardID,
  newBoardID,
}: {
  imageIDs: string[];
  currentBoardID: string;
  newBoardID: string;
}): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  console.log("HIT copyImages");
  try {
    // upload image details to api
    const apiUploadDetailsResponse = await apiFetcher<any>(
      "/board/update/images",
      "POST",
      {
        body: {
          currentBoardID: currentBoardID,
          newBoardID: newBoardID,
          imageIDs: imageIDs,
        },
      }
    );

    console.log(apiUploadDetailsResponse);

    if (
      apiUploadDetailsResponse.status === 200 ||
      apiUploadDetailsResponse.status === 202
    ) {
      return {
        rStatus: "success",
        rMessage: "Images copied successfully",
      };
    } else {
      console.log("Failed to copy images");
      return {
        rStatus: "error",
        rMessage: "Failed to copy images",
      };
    }
  } catch (error) {
    console.error("An error occurred while copying images:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while copying images",
    };
  }
}
