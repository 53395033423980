import React, { useEffect } from "react";
import KoalaImage from "../../assets/images/koala/KoalaTemp.webp";
import "./searchResult.scss";

type SearchResultProps = {
  searchType: string | undefined;
  searchTags: string | undefined;
  // for bulk actions
  setShowBulk: (showBulk: boolean) => void;
  showBulk: boolean;
  selectedBulkImageIds: string[]; // Added prop for selectedBulkImageIds
  setBulkSelectedImageIds: (imageIds: string[]) => void;
  selectedImageIds: string[];
  setSelectedImageIds: React.Dispatch<React.SetStateAction<string[]>>;
};

const SearchResult: React.FC<SearchResultProps> = ({
  searchType,
  searchTags,
  // For bulk actions
  setShowBulk,
  showBulk,
  selectedBulkImageIds,
  setBulkSelectedImageIds,
  selectedImageIds,
  setSelectedImageIds,
}) => {
  const tagArray = searchTags?.split(" ") || [];

  // useeffect that logs tagArray once
  useEffect(() => {
    console.log(tagArray);
  }, []);

  return (
    <div
      id="search_results_page_component_container"
      className="uk-flex uk-grid"
    >
      <div
        id="search_results_page_component_container_inner"
        className="uk-width-1-1"
      >
        <div
          id="search_results_page_component_container_inner_top"
          className={`uk-width-1-1 inner_top_flex ${
            searchType === "keyword" ? "keyword" : ""
          }`}
        >
          <img
            id="search_results_koala_image"
            className=""
            src={KoalaImage}
            alt="MDI Koala"
          />
          {searchType === "smart" && (
            <div id="search_results_koala_speech" className="">
              {/* Search results */}
              <p>
                {searchTags} {searchType} <br></br> Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Magni deleniti ipsum quaerat fugit
                accusantium, laborum blanditiis voluptatem esse delectus
                praesentium nesciunt quidem modi obcaecati assumenda nisi quam
                totam sunt mollitia?
              </p>
            </div>
          )}
        </div>
        <div
          id="search_results_page_component_container_inner_bottom"
          className="uk-width-1-1"
        >
          <h2>Related Images</h2>
          {/* <RelatedImagesContainer boardId={"boardId"} tagArray={tagArray} /> */}

          {/* Related images according to search (from sidebar) */}
          {tagArray !== undefined || tagArray !== null ? (
            <>
              {/* <PhotosContainer
                selectType="search"
                tagArray={tagArray}
                setShowBulk={setShowBulk}
                showBulk={showBulk}
                selectedBulkImageIds={selectedBulkImageIds}
                setBulkSelectedImageIds={setBulkSelectedImageIds}
                selectedImageIds={selectedImageIds}
                setSelectedImageIds={setSelectedImageIds}
              /> */}
            </>
          ) : (
            <>
              <div className="uk-width-1-1 search_result_photo_container">
                <div id="no_images_container">
                  <div className="no_image">
                    <div id="no_image_div">No related images found:</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
