import React, { createContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { AllBoards } from "../types";
import { loadAllBoards } from "../utilities/loadBoards.util";

interface BoardContextType {
  isBoardsLoaded: boolean; // Indicator for permissions loading completion
  allBoards?: AllBoards[];
  getBoardData: (getNewData: boolean) => Promise<void>;
}

export const BoardContext = createContext<BoardContextType | undefined>(
  undefined
);

export const BoardsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [allBoards, setAllBoards] = useState<AllBoards[]>();
  const [isBoardsLoaded, setIsBoardsLoaded] = useState(false); // Track if permissions are loaded

  //Toast Message
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    confirmButtonColor: "#3085d6",
    customClass: {
      popup: "custom-swal",
    },
    // timer: 2000,
  });

  //
  async function getBoardData(getNewData: boolean) {
    // Check for local storage
    //
    const fetchBoards = await loadAllBoards(getNewData);

    console.log("fetchBoards:", fetchBoards);
    if (typeof fetchBoards === "string") {
      Toast.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to fetch boards!",
        confirmButtonText: "Try Again",
        showCancelButton: true,
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          getBoardData(true);
        }
      });
    } else {
      console.log("Boards fetched", fetchBoards);
      if (typeof fetchBoards !== "string") {
        setAllBoards(fetchBoards.boards);
      }
    }
    setIsBoardsLoaded(true);
  }

  useEffect(() => {
    getBoardData(false);
    console.log("get board called");
  }, []);

  return (
    <BoardContext.Provider value={{ allBoards, isBoardsLoaded, getBoardData }}>
      {/* {isBoardsLoaded ? children : null}{" "} */}
      {children}
      {/* Only render children if permissions are loaded */}
    </BoardContext.Provider>
  );
};
