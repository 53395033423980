import React from "react";
import { ClientType } from "../../types";
import "./client.scss";

export type ClientProps = {
  client_id: string;
  client: ClientType;
  index: number;
  onClick: (client: ClientType) => void;
};

const Client: React.FC<ClientProps> = ({
  client,
  index,
  onClick,
  client_id,
}) => {
  return (
    <div
      id={`client_${client_id}`}
      className={`uk-card uk-card-default uk-card-small uk-margin-medium-bottom client_bg`}
      style={{
        backgroundImage: `url(${client.logo})`,
      }}
    >
      <div className="uk-card-footer">
        <span className="footer_title">{client.name}</span>
      </div>
    </div>
  );
};

export default Client;
