import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";

export type CreateAttribute = {
  message: {
    message: string;
    timestamp: string;
  };
};

// create attribute
export async function createAttribute(
  clientID: string,
  newAttribute: string
): Promise<string | CreateAttribute> {
  try {
    const response = await apiFetcher<any>("/client/attribute/add", "POST", {
      body: {
        clientID: clientID,
        newAttribute: newAttribute,
      },
    });

    console.log(response);

    if (response.status === 200 && response.data !== null) {
      console.log(response.data);
      return response.data as CreateAttribute;
    }

    if (response.status === 400) {
      Swal.fire({
        icon: "error",
        title: `Data format is incorrect`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return "Data format is incorrect";
    }

    if (response.status === 404) {
      console.log("Route not found");
      return "Route not found";
    }

    if (response.status === 409) {
      return "Attribute name already exists. Please choose another name";
    }

    console.log("Failed to create attribute");
    return "Failed to create attribute";
  } catch (error) {
    console.error("An error occurred while creating attribute:", error);
    return "An error occurred while creating attribute";
  }
}

export type DeleteAttribute = {
  message: {
    message: string;
    timestamp: string;
  };
};

//delete attribute
export async function deleteAttribute(
  clientID: string,
  attributeID: number
): Promise<string | DeleteAttribute> {
  try {
    const response = await apiFetcher<any>("/client/attribute/delete", "POST", {
      body: {
        clientID: clientID,
        attributeID: attributeID,
      },
    });

    console.log(response);

    if (response.status === 200 && response.data !== null) {
      console.log(response.data);
      return response.data as DeleteAttribute;
    }

    if (response.status === 400) {
      Swal.fire({
        icon: "error",
        title: `Data format is incorrect`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return "Data format is incorrect";
    }

    if (response.status === 404) {
      console.log("Route not found");
      return "Route not found";
    }

    console.log("Failed to delete attribute");
    return "Failed to create attribute";
  } catch (error) {
    console.error("An error occurred while deleting attribute:", error);
    return "An error occurred while deleting attribute";
  }
}

//delete attribute
export async function removeAttributeFromImage(
  clientID: string,
  attributeID: number,
  imageID: string
): Promise<string | DeleteAttribute> {
  try {
    const response = await apiFetcher<any>(
      "/client/attribute/attributevalue/delete",
      "POST",
      {
        body: {
          clientID: clientID,
          attributeID: attributeID,
          imageID: imageID,
        },
      }
    );

    console.log(response);

    if (response.status === 200 && response.data !== null) {
      console.log(response.data);
      return "success";
    }

    if (response.status === 400) {
      Swal.fire({
        icon: "error",
        title: `Data format is incorrect`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return "Data format is incorrect";
    }

    if (response.status === 404) {
      console.log("Route not found");
      return "Route not found";
    }

    console.log("Failed to remove attribute");
    return "Failed to remove attribute";
  } catch (error) {
    console.error("An error occurred while removing the user-attribute", error);
    return "An error occurred while removing the user-attribute";
  }
}
