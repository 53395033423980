import localforage from "localforage";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as DeleteAttribute } from "../../assets/images/icons/trash-can.svg";
import Koala from "../../assets/images/koala/koala-spinner.gif";
import { useAttributeContext } from "../../context/UserAttributeContext";
import { deleteAttribute } from "../../models/attributes.model";
import { fetchAttributes, updateAttributes } from "../../models/wsc.model";
import { ManageAttributes } from "../../types";
import "./manageWSCAttributes.scss";

interface ManageWSCAttributesProps {
  client_Id: string;
  setWindowToDisplay: (window: string) => void;
  allAttributes: ManageAttributes[];
  setAllAttributes: (allAttributes: ManageAttributes[]) => void;
  attributesLoaded: boolean;
  setAttributesLoaded: (attributesLoaded: boolean) => void;
}
const ManageWSCAttributes: React.FC<ManageWSCAttributesProps> = ({
  client_Id,
  setWindowToDisplay,
  allAttributes,
  setAllAttributes,
  attributesLoaded,
  setAttributesLoaded,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [allAttributes, setAllAttributes] = useState<ManageAttributes[]>([]);
  const [editedAttributes, setEditedAttributes] = useState<ManageAttributes[]>(
    []
  );
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: number]: boolean;
  }>({});
  const [searchAttribute, setSearchAttribute] = useState<string>("");
  const [filteredAttributes, setFilteredAttributes] = useState<
    ManageAttributes[]
  >([]);
  const [hasBeenEdited, setHasBeenEdited] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<{ [key: number]: string }>(
    {}
  );

  const userAttributeContext = useAttributeContext();
  const navigate = useNavigate();

  const [reloadPage, setReloadPage] = useState(false);

  const [maxCheckboxes, setMaxCheckboxes] = useState(20);
  const countRef = useRef(0);

  useEffect(() => {
    const initialCount = Object.values(checkboxStates).filter(
      (checked) => checked
    ).length;
    countRef.current = initialCount;
  }, [checkboxStates]);

  useEffect(() => {
    setIsLoading(true);
    const getAttributes = async () => {
      let attributes = await fetchAttributes(client_Id);

      if (typeof attributes === "string") {
        Swal.fire({
          icon: "error",
          title: "Failed to fetch attributes",
          text: attributes,
        });
        setAllAttributes([]);
        setIsLoading(false);
      } else {
        setAllAttributes(attributes);
        setIsLoading(false);
        setAttributesLoaded(true);
        setCheckboxStates(
          attributes.reduce(
            (acc, attribute) => ({ ...acc, [attribute.id]: attribute.active }),
            {}
          )
        );
      }
    };
    getAttributes();
  }, [reloadPage]);

  //For Searching
  useEffect(() => {
    if (allAttributes) {
      if (searchAttribute) {
        setFilteredAttributes(
          allAttributes.filter(
            (attribute) =>
              attribute.code &&
              typeof attribute.code === "string" &&
              attribute.code
                .toLowerCase()
                .includes(searchAttribute.toLowerCase())
          )
        );
      } else {
        setFilteredAttributes(allAttributes);
      }
    }
  }, [allAttributes, searchAttribute]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    attribute: ManageAttributes
  ) => {
    const { value } = e.target;

    // Validate the input
    const isValid = /^[^ ,"-]*$/.test(value);
    if (!isValid || value === "") {
      // Set the error message
      setErrorMessages({
        ...errorMessages,
        [attribute.id]:
          "Invalid input. Spaces, commas, double quotes, hyphens, and dashes are not allowed.",
      });
    } else {
      // Clear the error message for this attribute
      const { [attribute.id]: _, ...rest } = errorMessages;
      setErrorMessages(rest);
    }

    // Update allAttributes
    const newAttributes = allAttributes.map((a) => {
      if (a.id === attribute.id) {
        return { ...a, code: value };
      }
      return a;
    });
    setAllAttributes(newAttributes);

    // Update the attribute that was edited
    const index = editedAttributes.findIndex((a) => a.id === attribute.id);
    if (index !== -1) {
      editedAttributes[index].code = value;
    } else {
      const editedAttribute = { ...attribute, code: value };
      setEditedAttributes([...editedAttributes, editedAttribute]);
    }

    // Set hasBeenEdited to true only if the new value is different from the original value
    setHasBeenEdited(value !== attribute.code);
  };

  const checkBoxLimitConfirm = (attributeID: number, checked: boolean) => {
    if (checked) {
      if (countRef.current >= maxCheckboxes) {
        Swal.fire({
          title: `Attribute Active Limit Reached of ${maxCheckboxes}`,
          text: "Please deselect other attributes before selecting new ones",
          icon: "warning",
          iconColor: "#BB2D3B",
          confirmButtonText: "OK",
        });
        return false;
      } else {
        countRef.current++;
      }
    } else {
      countRef.current--;
    }
    return true;
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    attribute: ManageAttributes
  ) => {
    const { checked } = e.target;

    if (checkBoxLimitConfirm(attribute.id, checked)) {
      // Update the attribute that was edited
      const index = editedAttributes.findIndex((a) => a.id === attribute.id);
      if (index !== -1) {
        editedAttributes[index].active = checked;
      } else {
        const editedAttribute = { ...attribute, active: checked };
        setEditedAttributes([...editedAttributes, editedAttribute]);
      }

      // Update the checkbox state
      setCheckboxStates({ ...checkboxStates, [attribute.id]: checked });

      // Set hasBeenEdited to true only if the new value is different from the original value
      setHasBeenEdited(checked !== attribute.active);
    }
  };

  // Call for and then perform Deletion of Attribute
  const performAttributeDeletion = async (
    clientID: string,
    attributeID: number
  ) => {
    const attributeDeletionResults = await deleteAttribute(
      clientID,
      attributeID
    );

    if (typeof attributeDeletionResults === "string") {
      Swal.fire({
        title: "Failed to Delete Attribute",
        text: attributeDeletionResults,
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else {
      userAttributeContext.updateAttributeData(clientID);

      Swal.fire({
        title: "Attribute Deleted",
        icon: "success",
        showCloseButton: true,
      });
      setReloadPage(reloadPage == true ? false : true);
    }
  };

  // Attribute Deletion
  // CODES: 1 = WSC, 2 = System, 3 = User
  // Only User Attributes may be deleted
  //Confirm Deletion for Attribute
  const attributeDeletionConfirmation = (
    clientID: string,
    attributeID: number
  ) => {
    Swal.fire({
      title: "Are You Sure?",
      text: "Please type 'DELETE' to confirm:",
      input: "text",
      inputAttributes: {
        autocomplete: "off",
      },
      confirmButtonText: "Confirm Deletion",
      confirmButtonColor: "#BB2D3B",
      showCloseButton: true,
      cancelButtonText: "Cancel",
      showCancelButton: true,
      icon: "warning",
      iconColor: "#BB2D3B",
      showConfirmButton: true,
      inputValidator(value) {
        if (!value) {
          return "Please type 'DELETE' to confirm";
        }
        if (value !== "DELETE") {
          return "Please type 'DELETE' to confirm";
        }
      },
    }).then((result): void => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleting Attribute",
          text: "Please wait while the attribute is being deleted",
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        performAttributeDeletion(client_Id, attributeID);
      }
    });
  };

  // Attribute Deletion
  // CODES: 1 = WSC, 2 = System, 3 = User
  // Only User Attributes may be deleted
  const callAttributeDeletion = (attributeID: number, type: number) => {
    if (type && attributeID) {
      if (type === 1 || type === 2) {
        Swal.fire({
          icon: "error",
          iconColor: "#BB2D3B",
          title: "Cannot delete this attribute",
          text: "The deletion of WSC imported attributes or system-created attributes is not allowed. Only user-created attributes may be deleted. Please review your selections and try again.",
        });
        return;
      } else if (type == 3) {
        // Deletion Must be Confirmed First
        const confirmed = attributeDeletionConfirmation(client_Id, attributeID);
      } else {
        console.error("Unknown attribute 'type' code");
      }
    } else {
      console.error("Missing attribute ID or type");
    }
  };

  const clearLocalFilters = async () => {
    const keys = await localforage.keys();
    const keysToRemove = keys.filter(
      (key) =>
        key.includes(`filters-client-${client_Id}`) ||
        key.includes(`filters-board-${client_Id}`)
    );
    for (const key of keysToRemove) {
      await localforage.removeItem(key);
    }
  };

  const saveAttributes = async () => {
    if (editedAttributes.length <= 0) {
      Swal.fire({
        icon: "info",
        title: "No changes made",
        text: "No changes have been made to the attributes",
      });
      return;
    }
    //swal loader
    Swal.fire({
      title: "Updating attributes",
      text: "Please wait while the attributes are being updated",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });
    let updatingAttributes = await updateAttributes(
      client_Id,
      editedAttributes
    );

    if (updatingAttributes === "success") {
      Swal.fire({
        icon: "success",
        title: "Attributes updated successfully",
        text: "Attributes have been updated successfully",
        cancelButtonText: "Ok",
        showCancelButton: true,
        confirmButtonText: "Go to previous page",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
      clearLocalFilters();
      userAttributeContext.updateAttributeData(client_Id);
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to update attributes",
        text: updatingAttributes,
      });
    }
    setEditedAttributes([]);
  };

  const closePage = () => {
    setWindowToDisplay("upload");
  };

  return (
    <>
      <div>
        <div className="uk-flex uk-flex-start search-container">
          <input
            type="text"
            className="uk-input search-input"
            placeholder="Search attribute"
            value={searchAttribute}
            onChange={(e) => setSearchAttribute(e.target.value)}
          />
        </div>
      </div>
      <div className="attribute-container">
        {isLoading ? (
          <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column spinner">
            <img
              src={Koala}
              alt="Loading..."
              style={{ width: "150px", height: "150px", marginTop: "10px" }}
            />
          </div>
        ) : (
          allAttributes && (
            <div className="uk-flex uk-flex-center uk-margin-top">
              <table className="attributes-table">
                <thead>
                  <tr>
                    <th>Attribute Question</th>
                    <th>Smart Name</th>
                    <th>Active</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAttributes.length > 0 ? (
                    filteredAttributes.map((attribute) => (
                      <tr key={attribute.id}>
                        <td>{attribute.name}</td>
                        <td>
                          <input
                            type="text"
                            value={attribute.code ? attribute.code : ""}
                            onChange={(e) => handleInputChange(e, attribute)}
                            className="smart-name-input"
                          />
                          {errorMessages[attribute.id] && (
                            <p className="error-message">
                              {errorMessages[attribute.id]}
                            </p>
                          )}{" "}
                        </td>
                        {/* Check box to display the active or inactive depends on attribute.active when we change we should be able to update it the same as the attributeName*/}
                        <td className="checkbox-column">
                          <input
                            type="checkbox"
                            className="uk-checkbox"
                            checked={checkboxStates[attribute.id] || false}
                            onChange={(e) => handleCheckboxChange(e, attribute)}
                          />
                        </td>
                        <td className="td-icon">
                          <DeleteAttribute
                            onClick={() =>
                              callAttributeDeletion(
                                attribute.id,
                                attribute.type
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} className="no-data">
                        No attributes found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )
        )}
        <div className="footer-controls ">
          <button className="uk-button btn-cancel" onClick={() => closePage()}>
            Cancel
          </button>
          <button
            className={`uk-button btn-save ${
              Object.keys(errorMessages).length > 0 || !hasBeenEdited
                ? "disabled"
                : "ready"
            }`}
            disabled={Object.keys(errorMessages).length > 0 || !hasBeenEdited}
            onClick={() => saveAttributes()}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default ManageWSCAttributes;
