import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { getSingleImageByID } from "../../models/images.model";
import { fetchSingleShareImage } from "../../models/shared-board.model";
import { ImageType } from "../../types";
import SingleImage from "../SingleImage/singleImage.component";
import "./sideModalPanel.scss";

type SidePanelProps = {
  sourceID: string;
  location: "left" | "right";
  contentType: string;
  onClose: () => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  //For Single images
  singleImageID: string;
  setSingleImageID: (image: string | null) => void;
  imageSource: string;
  // for bulk actions
  setShowBulk: (showBulk: boolean) => void;
  showBulk: boolean;
  selectedBulkImageIds: string[]; // Added prop for selectedBulkImageIds
  setBulkSelectedImageIds: (imageIds: string[]) => void;
  selectedImageIds: string[];
  setSelectedImageIds: React.Dispatch<React.SetStateAction<string[]>>;
};

// SidePanel component
const SidePanelModal: React.FC<SidePanelProps> = ({
  sourceID,
  location,
  contentType,
  onClose,
  // For bulk actions
  singleImageID,
  setSingleImageID,
  imageSource,
  setShowBulk,
  showBulk,
  selectedBulkImageIds,
  setBulkSelectedImageIds,
  selectedImageIds,
  setSelectedImageIds,
}) => {
  const sidePanelRef = useRef<HTMLDivElement>(null);
  let title = "Loading...";
  let content;
  const [position, setPosition] = useState<string>(location);
  const [singleImage, setSingleImage] = useState<ImageType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Close the modal when user clicks outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidePanelRef.current &&
        !sidePanelRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  // Fetch single image data
  useEffect(() => {
    const fetchSingleImage = async () => {
      setIsLoading(true);
      if (imageSource !== "shared") {
        const jsonData = await getSingleImageByID(sourceID, singleImageID);
        setIsLoading(false);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          Swal.fire({
            icon: "error",
            title: "Failed to fetch image",
            text: jsonData,
          });
          return;
        } else {
          setSingleImage(jsonData);
        }
      } else {
        const singleImage = await fetchSingleShareImage(
          sourceID,
          singleImageID
        );
        setIsLoading(false);
        if (typeof singleImage === "string") {
          Swal.fire({
            icon: "error",
            title: "Failed to fetch image",
            text: "There was an error fetching this image",
          });
          setSingleImage(null);
          return;
        } else {
          setSingleImage(singleImage);
        }
      }
    };
    fetchSingleImage();
  }, []);

  switch (contentType) {
    case "wscImport":
      title = "Import from WSC";
      break;
    case "singleImage":
      content = singleImage ? (
        <SingleImage
          singleImage={singleImage}
          setSingleImageID={setSingleImageID}
          setShowBulk={setShowBulk}
          showBulk={showBulk}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
          setShowSingleImageModal={() => setSingleImageID(null)}
          singleImageType={imageSource}
          sourceID={sourceID}
        />
      ) : (
        <div className="uk-width-1-1 search_result_photo_container ">
          <div id="no_images_container">
            <div className="no_image">
              <div id="no_image_div">Image not found</div>
            </div>
          </div>
        </div>
      );
      break;
    default:
      break;
  }

  const handleSwapDirection = () => {
    console.log("Swapping direction");
    setPosition(position === "left" ? "right" : "left");
  };

  // Determine the class for the side panel based on the location

  return (
    <div className="side-panel">
      <div className={"side-panel-container " + position}>
        <div ref={sidePanelRef} className={"side-bar"}>
          {isLoading ? (
            <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column modal-loader darkmode">
              {/* <img
                src={Koala}
                alt="Loading..."
                style={{ width: "150px", height: "150px" }}
              /> */}
              <div
                className="uk-spinner"
                uk-spinner="ratio: 2"
                style={{
                  width: "auto",
                  height: "auto",
                  marginTop: "20px",
                }}
              ></div>
            </div>
          ) : (
            <div className="content">{content}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SidePanelModal;
