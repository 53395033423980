import localforage from "localforage";
import Swal from "sweetalert2";
import { Attribute, ImageType } from "../types";
import { isImage, isValidImageResponse } from "./images.model";

//Validation function for filters
function isValidDateValue(value: unknown): value is Attribute {
  if (!isObject(value)) {
    console.log("Value is not an object");
    return false;
  }
  return true;
}

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value !== null;
}

//Function to validate images

interface SharedFiltersResponse {
  statusCode: number;
  response: Attribute;
}

export async function fetchSharedCachedFilters(
  sharedBoardToken: string,
  boardID: string
): Promise<SharedFiltersResponse | string> {
  console.log("Fetching shared filters");

  const getSharedFilters: string | null = await localforage.getItem(
    `filters-shared-${boardID}`
  );
  try {
    if (getSharedFilters === null || getSharedFilters === undefined) {
      console.log("No shared filters in local storage");
      const controller = new AbortController();
      const signal = controller.signal;
      const timeoutID = setTimeout(() => controller.abort(), 180000);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/shared/board/precache`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sharedBoardToken,
          }),
          signal,
        }
      );
      clearTimeout(timeoutID);
      if (response.status === 200) {
        const data = await response.json();

        const attributeData = data.response as Attribute;
        if (isValidDateValue(data)) {
          localforage.setItem(
            `filters-shared-${boardID}`,
            JSON.stringify(data)
          );

          return { statusCode: 200, response: attributeData };
        } else {
          Swal.fire({
            icon: "error",
            title: "Validation Error",
            text: "Failed to validate data",
          });
          return "Failed to validate data";
        }
      } else if (response.status === 204) {
        return { statusCode: 204, response: {} } as SharedFiltersResponse;
      } else if (response.status === 206) {
        return { statusCode: 206, response: {} } as SharedFiltersResponse;
      } else {
        console.log("Failed to get a response");
        return "Failed to get a response";
      }
    } else {
      const filterObject = JSON.parse(getSharedFilters);
      console.log("Filter Object", filterObject.response);

      return {
        statusCode: 200,
        response: filterObject.response,
      } as SharedFiltersResponse;
    }
  } catch (error) {
    console.log("Error fetching shared filters", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong",
    });
    return "Error fetching shared filters";
  }
}

export async function fetchingSharedImages(
  sharedBoardToken: string,
  boardID: string
): Promise<ImageType[]> {
  const getLocalImages: string | null = await localforage.getItem(
    `shared-board-images-${boardID}`
  );

  try {
    if (getLocalImages === null) {
      console.log("No images in local storage");
      // fetch images
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/shared/board/get`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sharedBoardToken,
            boardID,
          }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (isValidImageResponse(data)) {
          localforage.setItem(
            `shared-board-images-${boardID}`,
            JSON.stringify(data)
          );
          console.log("Data", data);
          return data.images;
        } else {
          Swal.fire({
            icon: "error",
            title: "Validation Error",
            text: "Failed to validate share boards",
          });
          console.log("Failed to validate ");
          return [] as ImageType[];
        }
      } else if (response.status === 204) {
        return [] as ImageType[];
      } else {
        console.log("Failed to get a response");
        return [] as ImageType[];
      }
    } else {
      let localImages = JSON.parse(getLocalImages);
      return localImages.images;
    }
  } catch (error) {
    console.log("Error fetching shared images", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong",
    });
    return [];
  }
}

export async function fetchSingleShareImage(
  sourceID: string,
  imageID: string
): Promise<ImageType | string> {
  const sharedBoardToken: string | null = await localforage.getItem(
    "sharedToken"
  );

  if (sharedBoardToken === null || sharedBoardToken === undefined) {
    Swal.fire({
      icon: "error",
      title: "Invalid URL",
      text: "The URL is invalid",
    });
    return "Invalid URL";
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shared/board/image/get`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          boardId: sourceID,
          imageID,
          sharedBoardToken,
        }),
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      if (isImage(data)) {
        return data;
      } else {
        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: "Failed to validate data",
        });
        return "Failed to validate data";
      }
    } else if (response.status === 204) {
      return "No content found";
    } else {
      console.log("Failed to get a response");
      return "Failed to get a response";
    }
  } catch (error) {
    console.log("Error fetching single image", error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong",
    });
    return "Failed to fetch image";
  }
}
