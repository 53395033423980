import React, { useEffect, useState } from "react";
import boardsData from "../../assets/data/boardsData.json";
import PhotosContainer from "../../components/Photos/photosContainer.component";
import Header from "../../infrastructure/Header/header.component";
import Sidebar from "../../infrastructure/Sidebar/sidebar.component";

import { useParams } from "react-router-dom";
import SidePanelModal from "../../components/SideModal/sideModalPanel.component";
import { AttributeToggle } from "../../types";

export type ViewSingleBoardProps = {
  onThemeChange: () => void;
  // id: string;
  // title: string;
  // images: string[];
  // active: boolean;
  // clientID: string;
};

const ViewSingleBoardPage: React.FC<ViewSingleBoardProps> = ({
  onThemeChange,
}) => {
  const [boardId, setBoardId] = useState<string | null>(null);
  const [showBulk, setShowBulk] = useState<boolean>(false);
  // state array containing the selected image ids from the Photo Container
  const [selectedBulkImageIds, setBulkSelectedImageIds] = useState<string[]>(
    []
  );
  const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);
  const { clientID, boardID } = useParams<{
    clientID: string;
    boardID: string;
  }>();

  //============================================================
  //For modal and side panel
  const [singleImageID, setSingleImageID] = useState<string | null>(null);
  const [showSingleImageModal, setShowSingleImageModal] =
    useState<boolean>(false);
  //============================================================
  //For filters and keywords and search
  const [selectedFilters, setSelectedFilters] = useState<
    Record<string, Record<string, string[]>>
  >({});
  const [andOrToggle, setAndOrToggle] = useState<AttributeToggle>("MIX");
  const [searchWord, setSearchWord] = useState<string>("");
  const [gptSearch, setGptSearch] = useState<string>("");
  const [btnSearchClicked, setBtnSearchClicked] = useState<boolean>(false);
  const [selectedSearchType, setSelectedSearchType] = useState<
    "smart" | "search" | "attributes"
  >("attributes");
  //============================================================
  const selectedBoard = boardsData.boards.find(
    (board) => board.id === boardId
  ) || {
    id: boardID || "",
    title: "",
    images: [],
    active: false,
    client_id: "",
  };

  useEffect(() => {
    setBoardId(boardID || null);
    document.title = (selectedBoard?.title || "Board") + " | MDI SIM";
  }, [boardID, selectedBoard]);

  return (
    <div id="dashboard" className="uk-grid">
      {/* This calls the header element from the infrastructure folder */}
      <div className="uk-width-1-1">
        <Header
          boardId={boardId}
          onThemeChange={onThemeChange}
          showBulk={showBulk}
          setShowBulk={setShowBulk}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
          selectType="board"
          parsedClientID={clientID!}
        />
      </div>
      {/* This calls the sidebar element from the infrastructure folder */}
      <div className="uk-width-1-3@s uk-width-1-5@m">
        <Sidebar
          searchOptions={["all"]}
          clientID={clientID!}
          boardID={boardID!}
          selectType="board"
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setSearchWord={setSearchWord}
          selectedSearchType={selectedSearchType}
          setSelectedSearchType={setSelectedSearchType}
          setGptSearch={setGptSearch}
          btnSearchClicked={btnSearchClicked}
          setBtnSearchClicked={setBtnSearchClicked}
          andOrToggle={andOrToggle}
          setAndOrToggle={setAndOrToggle}
        />
      </div>
      <div className="uk-width-2-3@s uk-width-4-5@m inner_container">
        {selectedBoard ? (
          <PhotosContainer
            selectType="board"
            boardId={boardID}
            setShowBulk={setShowBulk}
            showBulk={showBulk}
            selectedBulkImageIds={selectedBulkImageIds}
            setBulkSelectedImageIds={setBulkSelectedImageIds}
            selectedImageIds={selectedImageIds}
            setSelectedImageIds={setSelectedImageIds}
            selectedFilters={selectedFilters}
            searchWord={searchWord}
            selectedSearchType={selectedSearchType}
            gptSearch={gptSearch}
            btnSearchClicked={btnSearchClicked}
            clientID={clientID!}
            setSingleImageID={setSingleImageID}
            setShowSingleImageModal={setShowSingleImageModal}
            andOrToggle={andOrToggle}
          />
        ) : (
          <div
            id="single_image_page_component_container"
            className="uk-flex uk-grid no_image"
          >
            <div id="no_image_div">No Board Found with ID: {boardID}</div>
          </div>
        )}
      </div>
      {showSingleImageModal && singleImageID !== null && clientID && (
        <SidePanelModal
          sourceID={clientID}
          showModal={showSingleImageModal}
          setShowModal={() => setShowSingleImageModal(false)}
          singleImageID={singleImageID}
          setSingleImageID={setSingleImageID}
          imageSource="board"
          location="right"
          contentType="singleImage"
          onClose={() => setShowSingleImageModal(false)}
          setShowBulk={setShowBulk}
          showBulk={showBulk}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
        />
      )}
    </div>
  );
};

export default ViewSingleBoardPage;
