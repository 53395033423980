import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import SearchGPTPage from "./pages/ChatGptSearch/searchGPT.page";
import DashboardPage from "./pages/Dashboard/dashboard.page";
import ErrorPage from "./pages/Error/error.page";
import ImagePage from "./pages/Image/image.page";
import LoggingInPage from "./pages/LoggingIn/loggingIn.page";
import LoginPage from "./pages/Login/login.page";
import PendingPage from "./pages/Pending/pending.page";
import ProfilePage from "./pages/Profile/profile.page";
import ResultPage from "./pages/ResultPage/result.page";
import UploadPage from "./pages/Upload/upload.page";
import ViewAllBoardsPage from "./pages/ViewAllBoards/viewAllBoards.page";
import ViewClientsPage from "./pages/ViewClients/viewClients.page";
import ViewSingleBoardPage from "./pages/ViewSingleBoard/viewSingleBoard.page";
import ViewSingleClientPage from "./pages/ViewSingleClient/viewSingleClient.page";
//Shared Boards
import SharedBoardPage from "./pages/SharedBoards/sharedBoards.page";

import "./App.scss";
import { ProtectedRoute } from "./utilities/ProtectedRoute";

const TitleUpdater = ({ title }: { title?: string }) => {
  // Updates the page title based on the current route
  useEffect(() => {
    if (title) {
      document.title = title + " | MDI SIM";
    } else {
      document.title = "MDI SIM";
    }
  }, [title]);
  return null;
};

function App() {
  const initialTheme = localStorage.getItem("theme") || "lightmode";
  const [theme, setTheme] = useState(initialTheme);
  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === "lightmode" ? "darkmode" : "lightmode"
    );
  };

  useEffect(() => {
    const root = document.getElementsByTagName("body")[0];
    if (theme === "darkmode") {
      root.classList.add("darkmode");
    } else {
      root.classList.remove("darkmode");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  // //useEffect that looks pending_user_email in local storage
  // useEffect(() => {
  //   const pendingUserEmail = localStorage.getItem("pending_user_email");
  //   console.log("pendingUserEmail", pendingUserEmail);
  // }, []);

  // Disable right click context menu, comment out for debugging
  // document.addEventListener("contextmenu", (e) => {
  //   e.preventDefault();
  // });

  return (
    <div className={`App ${theme}`}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route
            path="/login"
            element={
              <>
                <TitleUpdater title="Login" />
                <LoginPage />
              </>
            }
          />
          <Route
            path="/pending/:submitted?"
            element={
              <>
                <TitleUpdater title="Pending" />
                <PendingPage />
              </>
            }
          />
          <Route path="/verify-email" element={<Navigate to="/dashboard" />} />
          <Route
            path="/auth/:jwt?"
            element={
              <>
                <TitleUpdater title="Logging in" />
                <LoggingInPage />
              </>
            }
          />
          {/* Testing */}
          {/* <Route path="/dev" element={<DevPage />} /> */}
          {/* Shared Boards */}
          <Route
            path="/shared-board/:sharedUrl"
            element={
              <>
                <TitleUpdater title="Shared Board" />
                <SharedBoardPage />
              </>
            }
          />
          {/* Single View Image for boards */}
          {/* <Route
            path="/shared/image/:imageID"
            element={
              <>
                <TitleUpdater title="Board" />
                <SingleSharedBoardImage />
              </>
            }
          /> */}
          {/* Protected Routes */}
          <Route element={<ProtectedRoute />}>
            <Route
              path="/dashboard"
              element={
                <>
                  <TitleUpdater title="Dashboard" />
                  <DashboardPage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/image/:id"
              element={
                <>
                  <TitleUpdater title="Image" />
                  <ImagePage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/image"
              element={
                <>
                  <TitleUpdater title="Image" />
                  <ImagePage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/all-boards"
              element={
                <>
                  <TitleUpdater title="All Boards" />
                  <ViewAllBoardsPage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/:clientID/board/:boardID"
              element={
                <>
                  <TitleUpdater title="Board" />
                  <ViewSingleBoardPage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/upload/:clientID"
              element={
                <>
                  <TitleUpdater title="Upload" />
                  <UploadPage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/all-clients"
              element={
                <>
                  <TitleUpdater title="Projects" />
                  <ViewClientsPage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/client/:id"
              element={
                <>
                  <TitleUpdater title="Projects" />
                  <ViewSingleClientPage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/search/:searchType/:searchTags"
              element={
                <>
                  <TitleUpdater title="Searching" />
                  <SearchGPTPage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/result/:searchType/:searchTags"
              element={
                <>
                  <TitleUpdater title="Search Result" />
                  <ResultPage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/profile"
              element={
                <>
                  <TitleUpdater title="Profile" />
                  <ProfilePage onThemeChange={toggleTheme} />
                </>
              }
            />
            <Route
              path="/home"
              element={<Navigate to="/dashboard" replace />}
            />
            <Route
              path="/index"
              element={<Navigate to="/dashboard" replace />}
            />
          </Route>
          <Route
            path="/404"
            element={
              <>
                <TitleUpdater title="Error" />
                <ErrorPage />
              </>
            }
          />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
