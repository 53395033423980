import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { encodedMFAConfirm } from "../../utilities/encoding.util";
import { requestAndSetMfaTokens } from "../../utilities/tokenHandler.util";

export type TwoFactorProps = {
  handleEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

const TwoFactor: React.FC<TwoFactorProps> = ({
  handleEmailChange,
  email,
  setEmail,
}) => {
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // State to indicate loading
  const [tfUnformattedCode, setTfUnformattedCode] = useState(""); // This will check for unformatted mfa code
  const [loginFailed, setLoginFailed] = useState(false); // State to indicate failed login attempt
  const [tfFailed, setTfFailed] = useState(false); // State to indicate failed MFA attempt

  const [errorMessage, setErrorMessage] = useState("");

  // For resending MFA
  const [resendCounter, setCounter] = useState(20); // Initial counter value

  // const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
  //     setEmail(e.target.value);
  // };

  const setError = (message: string) => {
    setErrorMessage(message);
  };

  const clearError = () => {
    setErrorMessage("");
  };

  //Changes on password change
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  //Changes on code change
  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const filteredValue = value.replace(/[^0-9 ]/g, "");
    setTfUnformattedCode(filteredValue);
  };

  // Formats the code
  const formatCode = (unformattedCode: string) => {
    // Use a regular expression to match and extract only the digits
    const formattedCode = unformattedCode.match(/\d+/g);

    // Join the extracted digits into a single string
    return formattedCode ? formattedCode.join("") : "";
  };

  // const handleAuthCheck = () => {
  //   setAuthCheck(true);
  // };

  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    // console.log("login button clicked");

    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // TODO: Add API call to send magic link to email

      //Validate for empty fields and email format
      if (!emailRegex.test(email)) {
        setLoginFailed(true);
        setError("Please enter a valid email address.");
        return;
      }
      if (password === "") {
        setLoginFailed(true);
        setError("Please enter a password.");
        return;
      }
      if (tfUnformattedCode === "") {
        setLoginFailed(true);
        setError("Please enter a valid MFA code.");
        return;
      }

      //Validate email by regex

      //Convert mfa code to correct format

      const formattedCode = formatCode(tfUnformattedCode);
      const tfCode = formattedCode.toString();
      // the if statement to check empty string is redundant since its used at the start of function.
      // Extra checks are not bad though;]
      if (tfUnformattedCode !== "") {
        // This will call the formatCode function which will format the unformatted code that was entered
        // console.log("This is the pre formatted code", tfUnformattedCode);
        // console.log("This is the formatted code", tfCode);
      } else {
        //It should never hit this but just in case
        setLoginFailed(true);
        console.log("no code entered");
        setError("Please enter a valid code.");
        return;
      }

      const encodedMFAConfirmString = await encodedMFAConfirm(
        email,
        password,
        tfCode
      );

      // console.log(encodedMFAConfirmString);
      // If the encodedMFAConfirmString is null, then the login failed
      if (encodedMFAConfirmString === null) {
        setError("Invalid credentials.");
        setLoginFailed(true);
      } else {
        // console.log("encodedMFAConfirmString", encodedMFAConfirmString);
        // pass the mfaResponse string to /auth as state
        const requestingMFATokens = await requestAndSetMfaTokens(
          encodedMFAConfirmString
        );

        // console.log(requestingMFATokens);

        if (requestingMFATokens === "200") {
          // console.log(requestingMFATokens);
          navigate("/dashboard");
        } else {
          if (requestingMFATokens) {
            setErrorMessage(requestingMFATokens);
            setLoginFailed(true);
          }
        }
      }
    } finally {
      setLoading(false);
    }

    // Flush the cache
    if ("caches" in window) {
      // console.log(caches);
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }

    // Unregister the service worker
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div>
      <div className="uk-margin login_input_section">
        <>
          {/* Email */}
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Email:
            </label>
            <input
              className="uk-input"
              type="text"
              value={email}
              onChange={(e) => handleEmailChange(e)}
              placeholder="johnd@mdi-global.com"
            />
          </div>
          {/* Password */}
          <div className="uk-margin">
            <label className="uk-form-label" htmlFor="form-stacked-text">
              Password:
            </label>
            <input
              className="uk-input"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="**********"
            />
          </div>
        </>

        <div className="uk-margin">
          <label className="uk-form-label" htmlFor="form-stacked-text">
            Code:
          </label>
          <input
            className="uk-input"
            type="text"
            value={tfUnformattedCode}
            onChange={handleCodeChange}
            placeholder="12345"
          />
        </div>
      </div>
      <div className="uk-margin-top">
        <button
          className="uk-button btn_primary login_btn"
          onClick={handleLogin}
        >
          Login
        </button>
        <span
          className={`uk-margin-small-left span_incorrect  ${
            errorMessage ? `shown` : `hidden`
          }`}
        >
          {errorMessage}
        </span>
        {/* {!authCheck ? (
          <span
            className={`uk-margin-top span_incorrect  ${
              loginFailed ? `shown` : `hidden`
            }`}
          >
            Incorrect details
          </span>
        ) : (
          <span
            className={`uk-margin-top span_incorrect  ${
              tfFailed ? `shown` : `hidden`
            }`}
          >
            Invalid verification code. Please try again.
          </span>
        )} */}
      </div>
    </div>
  );
};

export default TwoFactor;
