import localforage from "localforage";
import Swal from "sweetalert2";
import {
  getBoardImagesByID,
  getCheckerStatus,
  getClientImages,
} from "../models/images.model";
import { ImageType } from "../types";

async function getCheckLatest(selectType: string, id: string) {
  const newDataChecker = await getCheckerStatus(id, selectType);
  let localData = await localforage.getItem(`ds-${selectType}-${id}-current`);
  if (
    localData &&
    typeof localData === "string" &&
    typeof newDataChecker === "number"
  ) {
    let parsedData = JSON.parse(localData);
    console.log(parsedData.lastReceivedData, newDataChecker, "here we are");

    // Check if expires_at is within 1 hour of the current time
    const currentTime = new Date().getTime();
    const expiresAt = new Date(parsedData.expires_at).getTime();
    const oneHourInMilliseconds = 60 * 60 * 1000;

    // If the data is not the same as the last received data or the data is about to expire, fetch new data
    if (
      newDataChecker !== parsedData.lastReceivedData ||
      expiresAt - currentTime <= oneHourInMilliseconds
    ) {
      let newData;
      if (selectType === "client") {
        newData = await getClientImages(id);
      } else if (selectType === "board") {
        newData = await getBoardImagesByID(id);
      }
      if (typeof newData === "string" && newData !== null) {
        return [];
      } else {
        //Set the data into local storage
        localforage.setItem(
          `ds-${selectType}-${id}-new`,
          JSON.stringify({
            lastReceivedData: newData && newData.updated_at,
            dataset: newData,
            status: "ready",
          })
        );
      }
    } else {
      console.log("No need to fetch");
    }
  } else {
    console.log("Failed to check latest updated at time");
    console.log(localData, newDataChecker, newDataChecker);
  }
}

export async function fetchingImages(
  selectType: string,
  id: string
): Promise<ImageType[]> {
  //Search for data in local storage
  //Should we not check -new
  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    width: "100%",
    padding: "1rem",
    confirmButtonColor: "#3085d6",
  });
  //Swal toast to set the -new to -current and clear -new
  const newestDataSet = await localforage.getItem(`ds-${selectType}-${id}-new`);
  if (
    newestDataSet &&
    typeof newestDataSet === "string" &&
    JSON.parse(newestDataSet).status === "ready"
  ) {
    //Show the toast
    Toast.fire({
      icon: "success",
      title: "New Data Loaded",
      text: "New Data has been loaded and is ready to be used.",
      width: "auto",
      padding: "1rem",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Update Now",
      showDenyButton: true,
      denyButtonColor: "#3085d6",
      denyButtonText: "Cancel",
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        //Set the new data to current
        await localforage.setItem(
          `ds-${selectType}-${id}-current`,
          JSON.stringify(JSON.parse(newestDataSet))
        );
        //Clear the new data
        await localforage.removeItem(`ds-${selectType}-${id}-new`);
        window.location.reload();
      }
    });
  }

  console.log("Getting Data", selectType, id);
  const localData: string | null = await localforage.getItem(
    `ds-${selectType}-${id}-current`
  );
  console.log(localData, "localData");
  //No data so this will be a initial load
  if (!localData || localData === null) {
    if (selectType === "client") {
      const clientData = await getClientImages(id);
      if (typeof clientData === "string") {
        return [];
      } else {
        //Set the data into local storage
        localforage.setItem(
          `ds-${selectType}-${id}-current`,
          JSON.stringify({
            lastReceivedData: clientData.updated_at,
            dataset: clientData,
            status: "ready",
          })
        );
        return clientData.images;
      }
    } else if (selectType === "board") {
      const boardData = await getBoardImagesByID(id);
      if (typeof boardData === "string") {
        return [];
      } else {
        //Set the data into local storage
        localforage.setItem(
          `ds-${selectType}-${id}-current`,
          JSON.stringify({
            lastReceivedData: boardData.updated_at,
            dataset: boardData,
            status: "ready",
          })
        );
        return boardData.images;
      }
    } else {
      return [];
    }
  } else {
    getCheckLatest(selectType, id);
    try {
      console.log(localData, "localData");
      const images = JSON.parse(localData).dataset.images;
      console.log(images, "images");
      return images;
    } catch (error) {
      console.log(error, "error");
      return [];
    }
  }
}
