import localforage from "localforage";
import { apiFetcher } from "../services/API.service";
import { ClientResponse, ClientType } from "../types";

//======================================================
//======================Validation=======================
//======================================================
//Check the received data is valid
function isValidClient(client: unknown): boolean {
  try {
    let error = [];
    //What do you want from me
    let clientData = client as ClientType;
    if (typeof client !== "object") {
      error.push("Client is not an object");
    }

    if (typeof clientData.client_id !== "number") {
      error.push("Client ID is not a number");
    }
    if (typeof clientData.name !== "string") {
      error.push("Client name is not a string");
    }
    if (typeof clientData.logo !== "string") {
      error.push("Client logo is not a string");
    }

    if (typeof clientData.wsc !== "boolean") {
      error.push("Client wsc is not a boolean");
    }

    // if (typeof clientData.logo_icon !== "string") {
    //   error.push("Client logo icon is not a string");
    // }
    // if (typeof clientData._status !== "string") {
    //   error.push("Client status is not a string");
    // }
    if (error.length > 0) {
      console.error("Client validation failed:", error.join(", "));
      return false;
    }

    return true;
  } catch (error) {
    console.error("Client validation failed:", error);
    return false;
  }
}

function isValidClientArray(clients: unknown): clients is ClientType[] {
  if (!Array.isArray(clients)) {
    return false;
  }

  for (const client of clients) {
    if (!isValidClient(client)) {
      return false;
    }
  }

  return true;
}

export async function fetchAllClients(): Promise<ClientResponse | string> {
  try {
    const response = await apiFetcher<unknown>("/client/all/get", "POST", {
      body: {},
    });

    if (
      response.status === 200 &&
      response.data &&
      typeof response.data === "object" &&
      "clients" in response.data &&
      response.data.clients !== null
    ) {
      if (isValidClientArray(response.data.clients)) {
        localforage.setItem("allClients", JSON.stringify(response.data));
        return response.data as ClientResponse;
      } else {
        return "Validation Failed";
      }
    } else if (response?.status === 204) {
      return { clients: [], expires_at: 0 };
    } else if (response.status === 404) {
      return "Not Found";
    } else if (response.status === 401) {
      return "Unauthorized";
    } else if (response.status === 500) {
      return "Internal Server Error";
    } else {
      return "Status failed to fetch clients";
    }
  } catch (error) {
    console.error("Failed to fetch clients:", error);

    return "Failed to fetch clients";
  }
}

export async function updateClientDetails(
  clientID: string,
  name: string
): Promise<string> {
  try {
    const response = await apiFetcher<any>("/client/edit", "POST", {
      body: { clientID, name },
    });

    if (response.status === 200) {
      return "success";
    } else if (response.status === 404) {
      return "Route not found";
    } else if (response.status === 401) {
      return "Unauthorized access";
    } else if (response.status === 500) {
      return "Internal Server Error";
    } else {
      return "Status failed to update client";
    }
  } catch (error) {
    console.error("Failed to update client:", error);

    return "Failed to update client";
  }
}
