import React, { useEffect, useState } from "react";
import PlaceholderImage from "../../assets/images/backgrounds/login-figma.webp";
import "./ImageLoader.scss";

interface ImageLoaderProps {
  width?: string;
  height?: string;
}

/**
 *
 * @param width
 * @returns
 */
const ImageLoader: React.FC<ImageLoaderProps> = ({ width, height }) => {
  const [eyesOpen, setEyesOpen] = useState(false);

  function wakingUp() {
    setEyesOpen(true);
  }
  function headPat() {
    console.log("Head is patted");
  }
  //Timer for closing the eyes
  useEffect(() => {
    if (eyesOpen) {
      const timerCountDown = setTimeout(() => {
        setEyesOpen(false);
      }, 10000);
      return () => clearTimeout(timerCountDown);
    }
  }, [eyesOpen]);

  return (
    <div
      className="background"
      style={{
        backgroundImage: `url(${PlaceholderImage})`,
        width: width,
        height: height,
      }}
    >
      <div className="textLoader"></div>
      <div className="koalaBackLeg-1"></div>
      <div className="koalaBackLeg-2"></div>
      <div className="koalaEar-1"></div>
      <div className="branch"></div>
      <div className="leaves"></div>
      <div className="tail"></div>
      <div className="koalaBody" onClick={() => wakingUp()}></div>
      <div className="koalaEar-2"></div>
      <div className="koalaHead" onClick={() => headPat()}>
        <div className={`faceDetails ${eyesOpen ? "openingEyes" : ""}`}></div>
      </div>
    </div>
  );
};

export default ImageLoader;
