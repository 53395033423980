import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { BoardContext } from "../../context/BoardsContext";
import { createBoard } from "../../models/board.model";
import { AllBoards, ClientType } from "../../types";
import { loadAllClients } from "../../utilities/loadClients.util";
import "./boardCreation.scss";

// export type AllBoards = {
//   id: string;
//   title: string;
//   coverImage: string;
//   active: boolean;
// }[];

const OpenBoardCreationModal = ({
  existingBoards,
  setModalOpen,
}: {
  existingBoards?: AllBoards[];
  setModalOpen: (open: boolean) => void;
}) => {
  const [clients, setClients] = useState<ClientType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const boards = useContext(BoardContext);

  // Used after a board is created.
  const callBoards = async () => {
    boards && (await boards.getBoardData(true));
  };

  useEffect(() => {
    setIsLoading(true);
    const getAllClients = async () => {
      let clients = await loadAllClients();
      if (typeof clients !== "string" && Array.isArray(clients.clients)) {
        setClients(clients.clients);
        setIsLoading(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: clients,
        });
        setIsLoading(false);
        setClients([]);
      }
    };
    getAllClients();
  }, []);

  const createNewBoard = async (boardName: string, clientID: string) => {
    const newBoardResults = await createBoard(boardName, clientID);
    if (typeof newBoardResults === "string") {
      Swal.fire({
        title: "Failed to Create Board",
        text: newBoardResults,
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else {
      await callBoards();
      Swal.fire({
        title: "Board Created",
        icon: "success",
        showCloseButton: true,
      });
    }
  };

  useEffect(() => {
    if (clients.length > 0) {
      Swal.fire({
        title: "Create New Board",
        html: `
        <div id="board_creation">
        <input id="board_name" class="swal2-input" placeholder="Board name">
        <select id="client_list" class="swal2-input">
        <option value="" disabled selected>Select a client</option>
        ${clients.map(
          (client) =>
            `<option value="${client.client_id}">${client.name}</option>`
        )}
        </select>
        </div>
        `,
        confirmButtonText: "Create",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        preConfirm: () => {
          const boardName = (
            document.getElementById("board_name") as HTMLInputElement
          ).value.trim();
          const clientID = (
            document.getElementById("client_list") as HTMLSelectElement
          ).value;

          if (!boardName) {
            Swal.showValidationMessage("Board name cannot be empty");
          }
          if (boardName.length > 64) {
            Swal.showValidationMessage(
              "Board name cannot be longer than 64 characters"
            );
          }
          if (boardName.length < 3) {
            Swal.showValidationMessage(
              "Board name cannot be shorter than 3 characters"
            );
          }
          if (existingBoards?.some((board) => board.title === boardName)) {
            Swal.showValidationMessage("Board name already exists");
          }

          if (!clientID) {
            Swal.showValidationMessage("Please select a client");
          }

          return { boardName, clientID };
        },
        didClose() {
          setModalOpen(false);
        },
      }).then((result): void => {
        if (result.isConfirmed) {
          const { boardName, clientID } = result.value;
          createNewBoard(boardName, clientID);
        }
        setModalOpen(false);
      });
    }
  }, [clients, existingBoards]);

  return null;
};

export default OpenBoardCreationModal;
