import {
  Base64Response,
  ExcelData,
  GPTSearch,
  HeaderBoard,
  ImageResponse,
  ImageType,
  Importing,
} from "../types";

import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";

//======================================================
//======================Validation=======================
//======================================================
//Check if the response is valid
export function isValidImageResponse(obj: unknown): obj is ImageResponse {
  try {
    let error = [];
    let imageResponse = obj as ImageResponse;
    console.log(obj);
    if (typeof imageResponse.success !== "boolean") {
      error.push("Success is not a boolean");
      console.log("Success is not a boolean");
    }
    if (!Array.isArray(imageResponse.images)) {
      error.push("Images is not an array");
      console.log("Images is not an array");
    }
    if (
      Array.isArray(imageResponse.images) &&
      !imageResponse.images.every((obj) => isImage(obj))
    ) {
      error.push("Images is not an array of ImageType");
      console.log("Images is not an array of ImageType");
    }

    if (typeof imageResponse.updated_at !== "number") {
      error.push("Updated at is not a number");
      console.log("Updated at is not a number");
    }

    if (typeof imageResponse.expires_at !== "number") {
      error.push("Expires at is not a number");
      console.log("Expires at is not a number");
    }

    if (error.length > 0) {
      console.error("Image response validation failed:", error);
      return false;
    }
    return true;
  } catch (error) {
    console.error("An error occurred while validating image response:", error);
    return false;
  }
}

export function isImage(obj: any): obj is ImageType {
  const validID = typeof obj.id === "string";
  const validTitle = typeof obj.title === "string";
  const validDescription = typeof obj.description === "string";
  const validKeywords =
    Array.isArray(obj.keywords) &&
    obj.keywords.every((keyword: unknown) => typeof keyword === "string");
  const validOcr = typeof obj.ocr === "string";
  const validSource = typeof obj.source === "string";
  const validAttributes =
    Array.isArray(obj.attributes) || obj.attributes === undefined;
  const validThumbnail =
    typeof obj.thumbnail === "string" || obj.thumbnail === undefined;
  const validDownload =
    typeof obj.download === "string" || obj.download === undefined;

  const AllValid =
    validID &&
    validTitle &&
    validDescription &&
    validKeywords &&
    validOcr &&
    validSource &&
    validAttributes &&
    validThumbnail &&
    validDownload;

  if (!AllValid) {
    console.log(obj);
    let errors = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
        let displayMessage = `<strong>Please contact support</strong><br><br>`;

        Swal.fire({
          icon: "error",
          title: `Invalid Image`,
          html: displayMessage + errors.join("<br>"),
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
        });
        return false;
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validTitle) {
      if (!obj.hasOwnProperty("title")) {
        errors.push("missing title");
      } else {
        errors.push(`Invalid type for title`);
      }
    }

    if (!validDescription) {
      if (!obj.hasOwnProperty("description")) {
        errors.push("missing description");
      } else {
        errors.push(`Invalid type for description`);
      }
    }

    if (!validKeywords) {
      if (!obj.hasOwnProperty("keywords")) {
        errors.push("missing keywords");
      } else {
        errors.push(`Invalid type for keywords`);
      }
    }

    if (!validOcr) {
      if (!obj.hasOwnProperty("ocr")) {
        errors.push("missing ocr");
      } else {
        errors.push(`Invalid type for ocr`);
      }
    }

    if (!validSource) {
      if (!obj.hasOwnProperty("source")) {
        errors.push("missing source");
      } else {
        errors.push(`Invalid type for source`);
      }
    }

    if (!validAttributes) {
      if (!obj.hasOwnProperty("attributes")) {
        errors.push("missing attributes");
      } else {
        errors.push(`Invalid type for attributes`);
      }
    }

    if (!validThumbnail) {
      if (!obj.hasOwnProperty("thumbnail")) {
        errors.push("missing thumbnail");
      } else {
        errors.push(`Invalid type for thumbnail`);
      }
    }

    if (!validDownload) {
      if (!obj.hasOwnProperty("download")) {
        errors.push("missing download");
      } else {
        errors.push(`Invalid type for download`);
      }
    }

    console.log(`Invalid imageID: ${obj.id}`);

    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `ImageID: ${obj.id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Image`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
    });
  }

  return AllValid;
}

function isDownloadImage(obj: unknown): obj is DownloadImages {
  let error = [];
  let downloadImages = obj as DownloadImages;
  if (!Array.isArray(downloadImages.imageFiles)) {
    error.push("ImageFiles is not an array");
    console.log("ImageFiles is not an array");
  }
  if (
    Array.isArray(downloadImages.imageFiles) &&
    !downloadImages.imageFiles.every(
      (obj) =>
        typeof obj.img_guid === "string" && typeof obj.img_title === "string"
    )
  ) {
    error.push("ImageFiles is not an array of ImageType");
    console.log("ImageFiles is not an array of ImageType");
  }

  if (error.length > 0) {
    console.error("Image response validation failed:", error);
    return false;
  }
  return true;
}

export async function fetchLatestData(
  clientId: string
): Promise<Importing | string> {
  try {
    const response = await apiFetcher<any>("/client/read/clientID", "POST", {
      body: {
        clientId: clientId,
      },
    });

    if (response.status === 200 && response.data !== null) {
      console.log(response.data);
      return response.data as Importing;
    } else if (response.status === 204) {
      return "No data found";
    } else if (response.status === 404) {
      console.log("Route not found");
      return "Route not found";
    } else {
      console.log("Failed to fetch latest data");
      return "Failed to fetch latest data";
    }
  } catch (error) {
    return "Failed to fetch latest data";
  }
}

export async function getClientImages(
  client_id: string
): Promise<ImageResponse | string> {
  try {
    const response = await apiFetcher<unknown>(
      "/images/all/read/clientID",
      "POST",
      {
        body: {
          clientID: client_id,
        },
      }
    );

    console.log(response);

    if (response.status === 200 && response.data !== null) {
      console.log(response.data);
      if (isValidImageResponse(response.data)) {
        return response.data;
      } else {
        console.log("Invalid image data received");
        return "Invalid image data received";
      }

      // NO IMAGES
    } else if (response.status === 204) {
      console.log("No images found");
      return "204";
    } else if (response.status === 404) {
      console.log("No images found");
      Swal.fire({
        icon: "error",
        title: "Page not found",
      });
      return "No images found";
    } else {
      console.log("Failed to fetch all images");
      Swal.fire({
        icon: "error",
        title: "Failed to fetch images",
      });
      return "Failed to fetch all images";
    }
  } catch (error) {
    console.error("An error occurred while fetching images:", error);
    Swal.fire({
      icon: "error",
      title: "An error occurred while fetching images",
    });
    return "An error occurred while fetching images";
  }
}

export async function getBoardImagesByID(
  boardID: string
): Promise<ImageResponse | string> {
  console.log("readSpecificBoard", boardID);
  try {
    const response = await apiFetcher<any>("/board/read/boardID", "POST", {
      body: {
        boardID: boardID,
      },
    });
    console.log(response);
    if (response.status === 200 && response.data !== null) {
      if (isValidImageResponse(response.data)) {
        return response.data;
      } else {
        console.log("Invalid image data received");
        return "Invalid image data received";
      }
    } else if (response.status === 204) {
      console.log("No images found");
      return "204";
    } else if (response.status === 404) {
      console.log("No images found");
      Swal.fire({
        icon: "error",
        title: "Page not found",
      });
      return "No images found";
    } else {
      console.log("Failed to fetch all images");
      Swal.fire({
        icon: "error",
        title: "Failed to fetch images",
      });
      return "Failed to fetch all images";
    }
  } catch (error) {
    console.error("An error occurred while fetching images:", error);
    Swal.fire({
      icon: "error",
      title: "An error occurred while fetching images",
    });
    return "An error occurred while fetching images";
  }
}

export async function getImagesByKeywords(
  keywords: string[]
): Promise<ImageType[] | string> {
  try {
    const response = await apiFetcher<any>("/readImagesByKeywords", "POST", {
      body: {
        keywords: keywords,
      },
    });

    console.log(response);

    if (response.status === 200 && response.data !== null) {
      // console.log(response.data);

      // THERE ARE IMAGES
      if (!Array.isArray(response.data)) {
        console.log("Data is not an array");
        return "Data is not an array";
      }

      if (response.data.every((obj) => isImage(obj))) {
        return response.data as ImageType[];
      } else {
        console.log("Invalid image data received");
        return "Invalid image data received";
      }

      // NO IMAGES
    } else if (response.status === 404) {
      console.log("No images found");
      return "No images found";
    } else {
      console.log("Failed to fetch all images");
      return "Failed to fetch all images";
    }
  } catch (error) {
    console.error("An error occurred while fetching images:", error);
    return "An error occurred while fetching images";
  }
}

export async function getSingleImageByID(
  sourceID: string,
  imageID: string
): Promise<ImageType | string> {
  try {
    const response = await apiFetcher<any>("/images/specific/get", "POST", {
      body: {
        // imageGuid: parseInt(imageID),
        clientID: sourceID,
        imageId: imageID,
      },
    });

    console.log(response);

    if (response.status === 200) {
      if (
        !response.data ||
        response.data === null ||
        response.data === undefined
      ) {
        return "Failed to fetch image";
      }
      // console.log(response.data);

      // THERE ARE IMAGES
      if (!isImage(response.data)) {
        console.log("Data format is incorrect");
        return "Data format is incorrect";
      }

      return response.data as ImageType;
    }

    if (response.status === 204) {
      console.log("No image found");
      return `No image found with ID: ${imageID}`;
    }

    if (response.status === 400) {
      console.log("Data format is incorrect");
      return "Please contact support";
    }

    if (response.status === 404) {
      console.log("Route not found");
      return "Route not found";
    }

    if (response.status === 500) {
      console.log("Internal server error");
      return `Internal server error`;
    }

    console.log("Failed to fetch image");
    return `Failed to fetch image`;
  } catch (error) {
    console.error("An error occurred while fetching image:", error);
    return "An error occurred while fetching image";
  }
}

//Check Status for caching is in Images.model.ts for re-useability

export async function getCheckerStatus(
  id: string,
  selectedType: string
): Promise<string | number> {
  try {
    const response = await apiFetcher<any>(
      `/${selectedType}/refresh/${selectedType}ID`,
      "POST",
      {
        body: {
          [`${selectedType}ID`]: id,
        },
      }
    );

    console.log([`${selectedType}ID`], id);

    console.log(response);

    if (response.status === 200) {
      if (response.data && typeof response.data.updated_at === "number") {
        return response.data.updated_at;
      } else {
        return "Valid data not found";
      }
    } else {
      return "No data found";
    }
  } catch (error) {
    console.error("An error occurred while fetching checker status:", error);
    return "An error occurred while fetching checker status";
  }
}

// Function to request a signed URL from the API
async function getSignedUrl(
  clientID: string,
  originalName: string
): Promise<
  | {
      uploadUrl: string;
      key: string;
    }
  | false
> {
  console.log("HIT getSignedUrl");
  try {
    const apiResponse = await apiFetcher<any>("/signed/url/get", "POST", {
      body: {
        clientID: clientID,
        fileName: originalName,
      },
    });

    console.log(apiResponse);

    if (apiResponse.status === 200 && apiResponse.data !== null) {
      console.log(apiResponse.data);

      if (
        apiResponse.data !== null &&
        apiResponse.data !== undefined &&
        apiResponse.data.uploadUrl !== null &&
        apiResponse.data.uploadUrl !== undefined &&
        apiResponse.data.key !== null &&
        apiResponse.data.key !== undefined
      ) {
        return {
          uploadUrl: apiResponse.data.uploadUrl,
          key: apiResponse.data.key,
        };
      } else {
        console.log("Image key not returned from api");
        Swal.fire({
          icon: "error",
          title: `Image key not returned from api`,
          html: `Please contact support`,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
        });
        return false;
      }
    }

    if (apiResponse.status === 400) {
      Swal.fire({
        icon: "error",
        title: `Data format is incorrect`,
        html: `Please contact support`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return false;
    }

    if (apiResponse.status === 500) {
      Swal.fire({
        icon: "error",
        title: `Internal server error`,
        html: `Please contact support`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return false;
    }

    throw new Error("Failed to create image");
  } catch (error) {
    console.error("An error occurred while creating image:", error);
    Swal.fire({
      icon: "error",
      title: `An error occurred while creating image`,
      html: `Please contact support`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
    });
    return false;
  }
}

// Function to upload image to S3
async function uploadImageToS3(
  signedUrl: string,
  source: Blob,
  type: string
): Promise<true | false> {
  console.log("HIT uploadImageToS3");
  console.log(signedUrl);
  console.log(source);
  console.log(type);
  try {
    const s3Response = await fetch(signedUrl, {
      method: "PUT",
      body: source,
    });

    console.log(s3Response);
    console.log(s3Response.status);
    // const data = await s3Response.json();
    // console.log(data);

    if (s3Response.status === 200) {
      // const data = await s3Response.json();
      // console.log(data);
      return true;
    } else {
      console.log("Failed to upload image to s3");
      Swal.fire({
        icon: "error",
        title: `Failed to upload image to s3`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return false;
    }
  } catch (error) {
    console.error("An error occurred while uploading image to s3:", error);
    Swal.fire({
      icon: "error",
      title: `An error occurred while uploading image to s3`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
    });
    return false;
  }
}

// Function to upload image details to API
async function sendImageData(
  clientID: string,
  originalName: string,
  title: string,
  description: string,
  keywords: string[],
  ocr: string,
  type: string,
  imgKey: string
): Promise<{ insertID: number } | false> {
  console.log("HIT sendImageData");
  try {
    // upload image details to api
    const apiUploadDetailsResponse = await apiFetcher<any>(
      "/image/create",
      "POST",
      {
        body: {
          clientID: clientID,
          newImage: {
            imgURL: imgKey,
            imgTags: keywords,
            imgOrgName: originalName,
            imgType: type,
            imgORC: ocr,
            imgDesc: description,
            imgTitle: title,
          },
        },
      }
    );

    console.log(apiUploadDetailsResponse);

    if (
      apiUploadDetailsResponse.status === 200 ||
      apiUploadDetailsResponse.status === 202
    ) {
      console.log(apiUploadDetailsResponse.data.newImage);
      return {
        insertID: apiUploadDetailsResponse.data.newImage,
      };
    } else {
      console.log("Failed to create image");
      Swal.fire({
        icon: "error",
        title: `Failed to create image`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
      });
      return false;
    }
  } catch (error) {
    console.error("An error occurred while creating image:", error);
    Swal.fire({
      icon: "error",
      title: `An error occurred while creating image`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
    });
    return false;
  }
}

export async function createImage({
  clientID,
  originalName,
  title,
  description,
  keywords,
  ocr,
  type,
  source,
}: {
  clientID: string;
  originalName: string;
  title: string;
  description: string;
  keywords: string[];
  ocr: string;
  type: string;
  source: Blob;
}): Promise<{ insertID: number } | string> {
  console.log("HIT createImage");
  console.log(clientID);
  console.log(originalName);
  console.log(title);
  console.log(description);
  console.log(keywords);
  console.log(ocr);
  console.log(type);
  console.log(source);
  try {
    const signedUrl = await getSignedUrl(clientID, originalName);
    console.log(signedUrl);

    if (signedUrl === false) {
      return "Failed to get signed url";
    }

    const uploadImage = await uploadImageToS3(
      signedUrl.uploadUrl,
      source,
      type
    );

    if (uploadImage === false) {
      return "Failed to upload image to s3";
    }

    const sendImageDetails = await sendImageData(
      clientID,
      originalName,
      title,
      description,
      keywords,
      ocr,
      type,
      signedUrl.key
    );

    if (sendImageDetails === false) {
      return "Failed to send image details";
    }

    console.log(sendImageDetails);

    console.log("Image created successfully ID:" + sendImageDetails.insertID);
    return { insertID: sendImageDetails.insertID };
  } catch (error) {
    console.error("An error occurred while creating image:", error);
    Swal.fire({
      icon: "error",
      title: `An error occurred while creating image`,
      html: `Please contact support`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
    });
    return "An error occurred while creating image";
  }
}

export async function addKeyword({
  clientID,
  imageIDs,
  keyword,
}: {
  clientID: string;
  imageIDs: string[];
  keyword: string;
}): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  console.log("HIT addKeyword");
  try {
    // upload image details to api
    const apiUploadDetailsResponse = await apiFetcher<any>(
      "/tags/image/add/bulk",
      "POST",
      {
        body: {
          clientID: clientID,
          affectedImages: imageIDs,
          newTag: keyword,
        },
      }
    );

    console.log(apiUploadDetailsResponse);

    if (
      apiUploadDetailsResponse.status === 200 ||
      apiUploadDetailsResponse.status === 202
    ) {
      return {
        rStatus: "success",
        rMessage: "Keyword added successfully",
      };
    } else {
      console.log("Failed to add keyword");
      return {
        rStatus: "error",
        rMessage: "Failed to add keyword",
      };
    }
  } catch (error) {
    console.error("An error occurred while adding keyword:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while adding keyword",
    };
  }
}

// Function to update image details to API
export async function updateImageData({
  clientID,
  id,
  title,
  description,
  keywords,
  ocr,
  url,
  rotate,
}: {
  clientID: string;
  id: string;
  title: string;
  description: string;
  keywords: string[];
  ocr: string;
  url: string;
  rotate?: string | null;
}): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  console.log("HIT updateImageData");
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
  try {
    // upload image details to api
    const apiUploadDetailsResponse = await apiFetcher<any>(
      "/image/specific/update",
      "POST",
      {
        body: {
          clientID: clientID,
          updatedImage: {
            imgID: parseInt(id),
            imgURL: url,
            imgTags: keywords,
            // imgOrgName: "",
            // imgType: "",
            imgOCR: ocr,
            imgDesc: description,
            imgTitle: title,
          },
          rotate: rotate ? rotate : null,
        },
      }
    );

    console.log(apiUploadDetailsResponse);

    if (
      apiUploadDetailsResponse.status === 200 ||
      apiUploadDetailsResponse.status === 202
    ) {
      return {
        rStatus: "success",
        rMessage: "Image updated successfully",
      };
    } else {
      console.log("Failed to update image");
      return {
        rStatus: "error",
        rMessage: "Failed to update image",
      };
    }
  } catch (error) {
    console.error("An error occurred while updating image:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while updating image",
    };
  }
}

// get latest boards
export async function getLatestBoards(): Promise<HeaderBoard[] | string> {
  try {
    const response = await apiFetcher<any>("/board/read/researcherID", "POST", {
      body: {},
    });

    if (response.status === 200 && response.data !== null) {
      // console.log(response.data);

      if (!response.data) {
        console.log("No boards object found");
        return "No boards object found";
      }

      // THERE ARE IMAGES
      if (!response.data || !Array.isArray(response.data)) {
        console.log("Data is not an array");
        return "Data is not an array";
      }

      if (response.data.every((obj: any) => isImage(obj))) {
        return response.data as HeaderBoard[];
      } else {
        console.log("Invalid image data received");
        return "Invalid image data received";
      }

      // NO IMAGES
    }

    if (response.status === 204) {
      console.log("No boards found");
      return "No boards found";
    }

    if (response.status === 404) {
      console.log("Route not found");
      return "Route not found";
    }

    if (response.status === 500) {
      console.log("Internal server error");
      return "Internal server error";
    }

    console.log("Failed to fetch all boards");

    return "Failed to fetch all boards";
  } catch (error) {
    console.error("An error occurred while fetching boards:", error);
    return "An error occurred while fetching boards";
  }
}

// Function to add images from client to board, takes a clientID and boardID and an array of imageIDs
export async function addImagesToBoard({
  clientID,
  boardID,
  imagesToAdd,
}: {
  clientID: string;
  boardID: string;
  imagesToAdd: string[];
}): Promise<{
  rStatus: "success" | "error" | "exists" | "not-found";
  rMessage: string;
}> {
  console.log("HIT addImagesToBoard");
  // Log the body
  console.log(clientID);
  console.log(boardID);
  console.log(imagesToAdd);
  try {
    const apiResponse = await apiFetcher<any>("/board/add/images", "POST", {
      body: {
        clientID: clientID,
        boardID: boardID,
        imagesToAdd: imagesToAdd,
      },
    });

    console.log(apiResponse);
    if (apiResponse.status === 200) {
      console.log("Images added to board successfully");
      return {
        rStatus: "success",
        rMessage: "Images added to board successfully",
      };
    } else if (apiResponse.status === 409) {
      console.log("Images already exist in board");
      return {
        rStatus: "exists",
        rMessage: "Images already exist in board",
      };
    } else if (apiResponse.status === 406) {
      console.log("Images already exist in board");
      return {
        rStatus: "exists",
        rMessage: "Images already exist in board",
      };
    } else if (apiResponse.status === 404) {
      console.log("Board not found");
      return {
        rStatus: "not-found",
        rMessage: "Board not found",
      };
    } else {
      console.log("Failed to add images to board");
      return {
        rStatus: "error",
        rMessage: "Failed to add images to board",
      };
    }
  } catch (error) {
    console.error("An error occurred while adding images to board:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while adding images to board",
    };
  }
}

// Function to copy images from board to board
export async function copyImagesToBoard({
  currentBoardID,
  nextBoardID,
  imagesToCopy,
}: {
  currentBoardID: string;
  nextBoardID: string;
  imagesToCopy: string[];
}): Promise<{
  rStatus: "success" | "error" | "exists" | "not-found";
  rMessage: string;
}> {
  console.log("HIT copyImagesToBoard");
  // Log the body
  console.log(currentBoardID);
  console.log(nextBoardID);
  console.log(imagesToCopy);
  try {
    const apiResponse = await apiFetcher<any>("/board/copy/images", "POST", {
      body: {
        currentBoardID: currentBoardID,
        nextBoardID: nextBoardID,
        imagesToCopy: imagesToCopy,
      },
    });

    console.log(apiResponse);
    if (apiResponse.status === 200) {
      console.log("Images copied to board successfully");
      return {
        rStatus: "success",
        rMessage: "Images copied to board successfully",
      };
    } else if (apiResponse.status === 409) {
      console.log("Images already exist in board");
      return {
        rStatus: "exists",
        rMessage: "Images already exist in board",
      };
    } else if (apiResponse.status === 406) {
      console.log("Images already exist in board");
      return {
        rStatus: "exists",
        rMessage: "Images already exist in board",
      };
    } else if (apiResponse.status === 404) {
      console.log("Board not found");
      return {
        rStatus: "not-found",
        rMessage: "Board not found",
      };
    } else {
      console.log("Failed to copy images to board");
      return {
        rStatus: "error",
        rMessage: "Failed to copy images to board",
      };
    }
  } catch (error) {
    console.error("An error occurred while copying images to board:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while copying images to board",
    };
  }
}

function isValidKeywords(search: unknown): search is GPTSearch {
  return (
    typeof search === "object" &&
    search !== null &&
    typeof (search as GPTSearch).description === "string" &&
    Array.isArray((search as GPTSearch).tags) &&
    (search as GPTSearch).tags.every((tag) => typeof tag === "string")
  );
}

// Function to fetch GPT Search
export async function getGPTKeywords(
  search: string
): Promise<GPTSearch | null> {
  console.log("Are we hitting", search);
  try {
    console.log("Are we hitting");
    const response = await apiFetcher<unknown>("/search/smart", "POST", {
      body: {
        search: search,
      },
    });
    console.log(response, "this is usS");
    if (response.status === 200 && isValidKeywords(response.data)) {
      return response.data;
    }

    console.error("Failed to fetch or validate keywords");
    return null;
  } catch (error) {
    console.error("Failed to connect to the server", error);
    return null;
  }
}

// Function to remove images from a board
export async function removeImagesFromBoard({
  boardID,
  imagesToRemove,
}: {
  boardID: string;
  imagesToRemove: string[];
}): Promise<{
  rStatus: "success" | "error" | "not-found";
  rMessage: string;
}> {
  console.log("HIT removeImagesFromBoard");
  // Log the body
  console.log(boardID);
  console.log(imagesToRemove);
  try {
    const apiResponse = await apiFetcher<any>("/board/remove/images", "POST", {
      body: {
        boardID: boardID,
        imagesToRemove: imagesToRemove,
      },
    });

    console.log(apiResponse);
    if (apiResponse.status === 200) {
      console.log("Images removed from board successfully");
      return {
        rStatus: "success",
        rMessage: "Images removed from board successfully",
      };
    } else if (apiResponse.status === 404) {
      console.log("Board not found");
      return {
        rStatus: "not-found",
        rMessage: "Board not found",
      };
    } else if (apiResponse.status === 204) {
      console.log("No images found in board");
      return {
        rStatus: "not-found",
        rMessage: "Image already removed, please refresh",
      };
    } else {
      console.log("Failed to remove images from board");
      return {
        rStatus: "error",
        rMessage: "Failed to remove images from board",
      };
    }
  } catch (error) {
    console.error("An error occurred while removing images from board:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while removing images from board",
    };
  }
}

// Function to move images from a board to another board
export async function moveImagesToBoard({
  currentBoardID,
  nextBoardID,
  imagesToMove,
}: {
  currentBoardID: string;
  nextBoardID: string;
  imagesToMove: string[];
}): Promise<{
  rStatus: "success" | "error" | "exists" | "not-found";
  rMessage: string;
}> {
  console.log("HIT moveImagesToBoard");
  // Log the body
  console.log(currentBoardID);
  console.log(nextBoardID);
  console.log(imagesToMove);
  try {
    const apiResponse = await apiFetcher<any>("/board/move/images", "POST", {
      body: {
        currentBoardID: currentBoardID,
        nextBoardID: nextBoardID,
        imagesToMove: imagesToMove,
      },
    });

    console.log(apiResponse);
    if (apiResponse.status === 200) {
      console.log("Images moved to board successfully");
      return {
        rStatus: "success",
        rMessage: "Images moved to board successfully",
      };
    } else if (apiResponse.status === 409) {
      console.log("Images already exist in board");
      return {
        rStatus: "exists",
        rMessage: "Images already exist in board",
      };
    } else if (apiResponse.status === 406) {
      console.log("Images already exist in board");
      return {
        rStatus: "exists",
        rMessage: "Images already exist in board",
      };
    } else if (apiResponse.status === 404) {
      console.log("Board not found");
      return {
        rStatus: "not-found",
        rMessage: "Board not found",
      };
    } else {
      console.log("Failed to move images to board");
      return {
        rStatus: "error",
        rMessage: "Failed to move images to board",
      };
    }
  } catch (error) {
    console.error("An error occurred while moving images to board:", error);
    return {
      rStatus: "error",
      rMessage: "An error occurred while moving images to board",
    };
  }
}
//======================================Single Image Download====================================
export async function downloadSingleImage(imageID: string): Promise<any> {
  try {
    const response = await apiFetcher<any>("/images/download/single", "POST", {
      body: {
        imageID: imageID,
      },
    });

    console.log(response);

    if (response.status === 200) {
      console.log("Image downloaded successfully");
      return "success";
    } else {
      console.log("Failed to download image");
      return "Failed to download image, from server";
    }
  } catch (error) {
    console.error("An error occurred while downloading image:", error);
    return "An error occurred while downloading image";
  }
}
type DownloadImages = {
  imageFiles: { img_guid: string; img_title: string }[];
};
//======================================Bulk Image Download======================================
export async function downloadBulkImages(
  clientID: string,
  imageList: string[]
): Promise<string> {
  try {
    const response = await apiFetcher<unknown>(
      "/images/download/bulk",
      "POST",
      {
        body: {
          clientID: clientID,
          imageList: imageList,
        },
      }
    );

    console.log(response);

    if (response.status === 200) {
      console.log("Images downloaded successfully");
      if (isDownloadImage(response.data)) {
        //here we ned to now download the images the image we get a imageFiles which is an array of images and we need to download them from s3 we get the img_guid which is the signed url and then we have the img_title
        //we should open a new tab and download the images
        let downloadImages = response.data as DownloadImages;
        console.log(downloadImages);
        let downloadLinks = downloadImages.imageFiles.map((img) => {
          return {
            img_guid: img.img_guid,
            img_title: img.img_title,
          };
        });

        downloadLinks.forEach((img, index) => {
          setTimeout(() => {
            fetch(img.img_guid)
              .then((response) => {
                if (response.ok) {
                  return response.blob();
                }
                throw new Error("Network response was not ok.");
              })
              .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = ""; // Optionally, set a filename here
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
              })
              .catch((error) => {
                console.error(
                  "There was a problem with the fetch operation:",
                  error
                );
              });
          }, 1000 * index);
        });

        return "success";
      } else {
        console.log("Invalid image data received");
        return "Invalid image data received";
      }
    } else {
      console.log("Failed to download images");
      return "Failed to download images, from server";
    }
  } catch (error) {
    console.error("An error occurred while downloading images:", error);
    return "An error occurred while downloading images";
  }
}

function isValidBase64(data: unknown): data is Base64Response {
  return (
    typeof data === "object" &&
    data !== null &&
    typeof (data as Base64Response).success === "boolean" &&
    typeof (data as Base64Response).xlsx === "string"
  );
}

export async function fetchCsvDocument(
  clientID: string,
  imageIDs: string[]
): Promise<ExcelData> {
  try {
    const response = await apiFetcher<unknown>(
      "/image/download/attributeCSV",
      "POST",
      {
        body: {
          clientID,
          imageIDs,
        },
      }
    );
    console.log(response);
    if (response.status === 202) {
      if (response) {
        return {
          statusCode: 202,
        };
      } else {
        console.log("Invalid CSV data received");
        return {
          statusCode: 500,
        };
      }
    } else {
      console.log("Failed to download CSV");
      return {
        statusCode: 500,
      };
    }
  } catch (error) {
    console.error("An error occurred while downloading CSV:", error);
    return {
      statusCode: 400,
    };
  }
}
// TODO Change imageId to imageID when the API is updated
export async function deleteImage(
  clientID: string,
  imageID: string
): Promise<string> {
  try {
    const response = await apiFetcher<unknown>(
      "/image/specific/delete",
      "POST",
      {
        body: {
          clientID: clientID,
          imageId: imageID,
        },
      }
    );

    console.log(response);

    if (response.status === 200) {
      console.log("Image deleted successfully");
      return "success";
    } else {
      console.log("Failed to delete image");
      return "Failed to delete image";
    }
  } catch (error) {
    console.log("Failed to delete image");
    return "Failed to delete image";
  }
}

export async function deleteBulkImages(
  clientID: string,
  imageIDs: string[]
): Promise<{ rStatus: "success" } | { rStatus: "error"; rMessage: string }> {
  try {
    const response = await apiFetcher<unknown>("/images/delete/bulk", "POST", {
      body: {
        clientID: clientID,
        imageIds: imageIDs,
      },
    });

    if (response.status === 200 || response.status === 202) {
      console.log("Image deleted successfully");
      return {
        rStatus: "success",
      };
    }

    return {
      rStatus: "error",
      rMessage: "Failed to delete images",
    };
  } catch (error) {
    console.log(error);
    return {
      rStatus: "error",
      rMessage: "Failed to delete images",
    };
  }
}
