import localforage from "localforage";
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ReactComponent as CarouselViewIcon } from "../../assets/images/icons/carouselView.svg";
import { ReactComponent as GridViewIcon } from "../../assets/images/icons/gridView.svg";
import { ReactComponent as ListViewIcon } from "../../assets/images/icons/listView.svg";
import Koala from "../../assets/images/koala/koala-spinner.gif";
import CarouselView from "../../components/DashboardContainer/CarouselView.component";
import { BoardContext } from "../../context/BoardsContext";
import Header from "../../infrastructure/Header/header.component";
import { ClientType, ViewLayout, ViewType } from "../../types";
import { loadAllClients } from "../../utilities/loadClients.util";
import "./dashboard.scss";

export type DashboardPageProps = {
  onThemeChange: () => void;
};

const DashboardPage: React.FC<DashboardPageProps> = ({ onThemeChange }) => {
  const [showBulk, setShowBulk] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<ClientType[]>([]);
  const boards = useContext(BoardContext);
  // state array containing the selected image ids from the Photo Container
  const [selectedBulkImageIds, setBulkSelectedImageIds] = useState<string[]>(
    []
  );
  const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);
  const [viewLayout, setSetViewLayout] = useState<ViewLayout>("carousel");
  const [selectedView, setSelectedView] = useState<ViewType>("clients");
  const VIEW_LAYOUT_KEY = "viewLayout";
  const SELECTED_VIEW_KEY = "selectedView";

  // Fetch all clients
  const getAllClients = async () => {
    let allClients = await loadAllClients();
    if (typeof clients !== "string" && Array.isArray(allClients.clients)) {
      setClients(allClients.clients);
      setIsLoading(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: allClients,
      });
      setIsLoading(false);
      setClients([]);
    }
  };

  const getAllBoards = async () => {
    console.log("Getting all boards");
    console.log("Board Data: " + boards);
    if (boards) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (selectedView === "clients") {
      getAllClients();
    } else {
      getAllBoards();
    }
  }, []);

  //Saving and layout option locally
  useEffect(() => {
    try {
      const storedViewType = localStorage.getItem(VIEW_LAYOUT_KEY);
      if (storedViewType) {
        setSetViewLayout(storedViewType as ViewLayout);
      } else {
        localStorage.setItem(VIEW_LAYOUT_KEY, viewLayout);
      }
    } catch (error) {
      console.error(`Error accessing localStorage: ${error}`);
    }
  }, []);

  const handleChangeLayout = (layout: ViewLayout) => {
    setSetViewLayout(layout);
    try {
      localStorage.setItem(VIEW_LAYOUT_KEY, layout);
    } catch (error) {
      console.error(`Error saving to localStorage: ${error}`);
    }
  };

  useEffect(() => {
    try {
      const storedSelectedView = localStorage.getItem(SELECTED_VIEW_KEY);
      if (storedSelectedView) {
        setSelectedView(storedSelectedView as "clients" | "boards");
      } else {
        localStorage.setItem(SELECTED_VIEW_KEY, selectedView);
      }
    } catch (error) {
      console.error(`Error accessing localStorage: ${error}`);
    }
  }, []);

  // TERMS AND CONDITIONS POPUP
  useEffect(() => {
    const checkAndHandleAcknowledgement = async () => {
      const acknowledged = await localforage.getItem("agreementAcknowledged");

      if (!acknowledged) {
        Swal.fire({
          title: "<b>Terms and Conditions</b>",
          html: `
            <p>By continuing to use this service, you acknowledge that you have read, understood, and agree to the Terms and Conditions below.</p>
            <iframe src="pdfs/Images for Market Research Purposes.pdf" width="100%" height="400px"
              style=" border-top-left-radius: 15px; border-top-right-radius: 15px;">
            </iframe>
            <p>Please confirm your acceptance of the Terms and Conditions to proceed</p>
          `,
          showDenyButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#3085d6",
          denyButtonText: "No",
          cancelButtonColor: "#183659",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            localforage.setItem("agreementAcknowledged", "true");
          } else {
            localforage.removeItem("agreementAcknowledged");
          }
        });
      } else {
      }
    };

    checkAndHandleAcknowledgement();
  }, []);

  const handleSelectedView = (view: "clients" | "boards") => {
    setSelectedView(view);
    try {
      localStorage.setItem(SELECTED_VIEW_KEY, view);
    } catch (error) {
      console.error(`Error saving to localStorage: ${error}`);
    }
  };

  return (
    <div id="dashboard" className="uk-grid">
      <div className="uk-width-1-1">
        <Header
          onThemeChange={onThemeChange}
          showBulk={showBulk}
          setShowBulk={setShowBulk}
          selectedImageIds={selectedImageIds}
          setSelectedImageIds={setSelectedImageIds}
          selectedBulkImageIds={selectedBulkImageIds}
          setBulkSelectedImageIds={setBulkSelectedImageIds}
          selectType="dashboard"
        />
      </div>
      <div className="uk-width-1 dashboard-container">
        <div className="uk-width-1 uk-text-center title-container">
          <div className="title-flex-container">
            <h1
              className={`title ${
                selectedView === "clients" ? "active-title" : "inactive-title"
              }`}
              onClick={() => handleSelectedView("clients")}
            >
              Projects
            </h1>
            <span className="divider">/</span>
            <h1
              className={`title ${
                selectedView === "boards" ? "active-title" : "inactive-title"
              }`}
              onClick={() => handleSelectedView("boards")}
            >
              Boards
            </h1>
            {/* different layouts */}
          </div>
        </div>
        {/* Better dashboard */}
        <div className="uk-width-1 uk-text-end layout-options">
          <p className="layout-title">Viewing Options: </p>
          <CarouselViewIcon
            className={`option-icons ${
              viewLayout === "carousel" ? "selected" : ""
            }`}
            onClick={() => handleChangeLayout("carousel")}
          />
          <GridViewIcon
            className={`option-icons ${
              viewLayout === "grid" ? "selected" : ""
            }`}
            onClick={() => handleChangeLayout("grid")}
          />
          <ListViewIcon
            className={`option-icons ${
              viewLayout === "list" ? "selected" : ""
            }`}
            onClick={() => handleChangeLayout("list")}
          />
        </div>
        <div
          className={`client ${
            selectedView === "clients" ? "selected" : "unselected"
          }`}
        >
          {isLoading ? (
            <div className="loading">
              <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                <img
                  src={Koala}
                  alt="Loading..."
                  style={{ width: "150px", height: "150px" }}
                />
              </div>
            </div>
          ) : (
            <div className="client-grid">
              {/* Better dashboard */}
              <CarouselView
                source={clients}
                sourceOrigin="client"
                viewLayout={viewLayout}
              />
              {/* <ClientsContainer
                boardId={boardId}
                showBulk={showBulk}
                clients={clients}
                numberOfColumns={4}
                maxHeight={56}
                showPagination={false}
              /> */}
            </div>
          )}
        </div>
        <div
          className={`boards ${
            selectedView === "boards" ? "selected" : "unselected"
          }`}
        >
          {/* <BoardsContainer boardId={boardId} showBulk={showBulk} /> */}
          {boards &&
          boards.isBoardsLoaded === true &&
          Array.isArray(boards.allBoards) ? (
            <CarouselView
              source={boards.allBoards}
              sourceOrigin="board"
              viewLayout={viewLayout}
            />
          ) : (
            <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
              <img
                src={Koala}
                alt="Loading..."
                style={{ width: "150px", height: "150px" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
