import ExcelJS from "exceljs";
import localforage from "localforage";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as EditIcon } from "../../../assets/images/icons/edit.svg";
import { ReactComponent as ImportIcon } from "../../../assets/images/icons/ImportIcon.svg";
import { ReactComponent as UploadIcon } from "../../../assets/images/icons/upload.svg";
import { useAttributeContext } from "../../../context/UserAttributeContext";
import {
  importAttributesCSV,
  sendReimportRequest,
} from "../../../models/wsc.model";
import {
  bodyData,
  bodySingleData,
  ClientResponse,
  ClientType,
  HeaderType,
} from "../../../types";
import "../uploadImage.scss";

interface WSCImportsProps {
  setWindowToDisplay: React.Dispatch<React.SetStateAction<string>>;
  openWscAttributeModal: () => void;
}

const WSCImports: React.FC<WSCImportsProps> = ({
  setWindowToDisplay,
  openWscAttributeModal,
}) => {
  const { clientID } = useParams<{ clientID: string }>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedClient, setSelectedClient] = useState<
    ClientType | null | "loading"
  >("loading");

  useEffect(() => {
    if (!clientID) {
      setSelectedClient(null);
      return;
    }

    const fetchClient = async () => {
      const allClients: string | null = await localforage.getItem("allClients");

      if (typeof allClients === "string") {
        try {
          const arrayOfClients: ClientResponse = JSON.parse(allClients);

          if (arrayOfClients.clients) {
            const client = arrayOfClients.clients.find(
              (client: ClientType) => client.client_id.toString() === clientID
            );
            if (client) {
              setSelectedClient(client);
            } else {
              setSelectedClient(null);
            }
          }
        } catch (error) {
          console.log("Error parsing client data", error);
          setSelectedClient(null);
        }
      }
    };

    fetchClient();
  }, [clientID]);

  // const getClientID = async () => {
  //   const allClients: string | null = await localforage.getItem("allClients");

  //   if (typeof allClients === "string") {
  //     try {
  //       const arrayOfClients: ClientResponse = JSON.parse(allClients);

  //       if (clientID && arrayOfClients.clients) {
  //         const client = arrayOfClients.clients.find(
  //           (client: ClientType) => client.client_id.toString() === clientID
  //         );
  //         return client?.name;
  //       }
  //     } catch (error) {
  //       console.log("Error parsing client data", error);
  //     }
  //   } else {
  //     return "Client not found";
  //   }
  // };

  const userAttributeContext = useAttributeContext();

  const openReimportModal = async () => {
    if (!selectedClient || selectedClient === "loading") {
      Swal.fire({
        title: "Client not found",
        text: "Please select a client from the dashboard",
        icon: "error",
      });
      return;
    }
    Swal.fire({
      title: selectedClient.wsc
        ? "Re-Import Images from WSC"
        : "Re-Import Auto board Images",
      html: `Confirm your action. Please be aware that proceeding with this request will require substantial processing resources and may incur significant costs. To proceed, please enter the client name <strong>${selectedClient.name}</strong> to confirm.`,
      input: "text",
      inputPlaceholder: "Client Name",
      showCancelButton: true,
      confirmButtonText: "Reimport",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Please enter the client name";
        }
        if (value !== selectedClient.name) {
          return "Client name does not match, Please try again";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Re-Import requested",
          html: "Please wait...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const reimportRequest = async () => {
          const reimportRequest = await sendReimportRequest(
            selectedClient.client_id
          );
          Swal.close();

          if (reimportRequest.rStatus === "success") {
            if (reimportRequest.rData === "normal") {
              Swal.fire({
                title: "Re-import request sent",
                text: "Thank you. The re-import process is now running in the background. We appreciate your patience.",
                icon: "success",
              });
            } else {
              Swal.fire({
                title: "Re-import WSC request sent",
                text: "Thank you. The re-import process is now running in the background. We appreciate your patience.",
                icon: "success",
              });
            }
          } else {
            Swal.fire({
              title: "Re-Import request failed",
              text: "Please try again",
              icon: "error",
            });
          }
        };
        reimportRequest();
      }
    });
  };

  // CSV UPLOAD

  // Display Initial CSV requirements to Client before file upload
  const displayCsvInfo = () => {
    Swal.fire({
      title: "CSV Import Information",
      icon: "info",
      html: `
        Please ensure that the CSV file you are importing is formatted correctly:<br><br>
         <div style="text-align: left;">
        1) Must contain Image ID<br><br>
        2) If a value is empty and there is an existing value for the image for that attribute, it will be removed<br><br>
        3) All spaces will be converted to underscores<br><br>
        4) If there are duplicate attributes, the second one will be ignored<br><br
    `,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleLabelClick();
      }
    });
  };

  const handleLabelClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file && clientID) {
      const inValidRows: any = [];
      const bodyData: bodyData = [];

      const reader = new FileReader();
      reader.onload = async (e) => {
        const fileContent = e.target?.result;
        let lines: string[] = [];

        console.log(file.type);

        // Handle CSV and Excel files
        if (
          file.type === "text/csv" ||
          file.type === "application/vnd.ms-excel"
        ) {
          let csvContent: string | null = null;

          if (typeof fileContent === "string") {
            csvContent = fileContent; // CSV content for Chrome and correct MIME type
          } else if (fileContent instanceof ArrayBuffer) {
            // Firefox may treat CSV as ArrayBuffer, decode it
            csvContent = new TextDecoder("utf-8").decode(fileContent);
          }

          if (csvContent) {
            lines = csvContent.split("\n"); // Split CSV into lines
            console.log("CSV Content:", csvContent);
          } else {
            console.error("Unable to process CSV file.");
            return;
          }
        } else if (
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          file.type === "application/vnd.ms-excel"
        ) {
          const workbook = new ExcelJS.Workbook();

          try {
            // Convert excel file to CSV and split into lines (string [])
            const arrayBuffer = fileContent as unknown as ArrayBuffer;
            await workbook.xlsx.load(arrayBuffer); // Load the Excel file as ArrayBuffer
            const worksheet = workbook.worksheets[0]; // Get the first worksheet
            const csvContent = worksheetToCSV(worksheet); // Convert worksheet to CSV
            lines = csvContent.split("\n"); // Split CSV into lines
            console.log("Excel Content converted to CSV:", csvContent);
          } catch (error) {
            console.error("Error processing Excel file:", error);
            return;
          }
        } else {
          Swal.fire({
            title: "Unsupported file format",
            text: "Please upload a CSV or Excel file",
            icon: "error",
          });
          resetFileInput();
          return;
        }

        Swal.fire({
          title: "Uploading...",
          text: "Please wait while the CSV is being uploaded.",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        // determine delimiter used in heading line
        const firstLine = lines[0];
        let delimiter = detectDelimiter(firstLine);

        // Compulsory Check for 'ID' as first item in header
        if (lines[0]?.split(delimiter)[0].trim() === "ID") {
          // Header processing and body transformation
          const headerRow: HeaderType = { ID: 0 };
          const headers = lines[0].split(delimiter);

          headers.forEach((header, index) => {
            const trimmedHeader = header
              .trim()
              .replace(/['"[^a-zA-Z0-9_]]/g, "");
            if (trimmedHeader !== "ID") {
              headerRow[trimmedHeader] = index;
            }
          });

          const headerMap = new Map<string, number>();

          lines.forEach((line, index) => {
            const bodyDataTransformed: bodySingleData = [];
            let isValidRow = true;

            if (index === 0) {
              // Skip the header row but build the header map for indexing
              const headers = line.split(delimiter);

              headers.forEach((header, idx) => {
                const trimmedHeader = header
                  .trim()
                  .replace(/['"]/g, "")
                  .replace(/[^a-zA-Z0-9_]/g, "");
                headerMap.set(trimmedHeader, idx);
              });

              return; // Skip the header row
            }

            // Process each row (line) in the CSV file
            const splitLine = (line: string, delimiter: string): string[] => {
              const regex = new RegExp(
                `(${delimiter})(?=(?:[^"]*"[^"]*")*[^"]*$)`
              );
              return line.split(regex).filter((cell) => cell !== delimiter);
            };

            const cells = splitLine(line, delimiter);

            for (let innerIndex = 0; innerIndex < cells.length; innerIndex++) {
              let cell = cells[innerIndex].trim(); // Trim whitespace

              if (isValidRow) {
                // Remove surrounding quotes, if any
                cell = cell.replace(/^["']|["']$/g, "");

                // Check if the cell is empty (skip ID column check)
                if (cell === "" && innerIndex !== 0 && isValidRow) {
                  bodyDataTransformed.push(""); // Push empty value for blank cells
                  continue;
                }
                debugger;
                // Check first val in row for valid number (ID)
                if (innerIndex === 0 && isValidRow) {
                  const id = parseInt(cell);
                  if (isNaN(id)) {
                    inValidRows.push(index);
                    isValidRow = false; // Track invalid rows
                    break;
                  } else {
                    bodyDataTransformed.push(cell); // Add valid ID
                  }
                } else if (isValidRow) {
                  // For other columns, remove spaces and replace delimiter (to prevent delimiter conflicts)
                  const transformedCell = cell
                    .replace(/\s/g, "_") // Replace spaces with underscores
                    .replace(new RegExp(delimiter, "g"), "") // Remove delimiter characters
                    .replace(/[^a-zA-Z0-9_]/g, "");
                  bodyDataTransformed.push(transformedCell);
                }
              }
            }
            // Only push valid rows with transformed data
            if (isValidRow) {
              bodyData.push(bodyDataTransformed);
            }
          });

          if (bodyData.length > 0) {
            const csvUploadResponse: string | number =
              await importAttributesCSV({
                clientID: clientID,
                headerAttributes: headerRow,
                bodyAttributes: bodyData,
              });
            if (typeof csvUploadResponse === "string") {
              if (csvUploadResponse === "success") {
                Swal.fire({
                  title: "CSV Import Successful",
                  text: "Attributes have been imported successfully",
                  icon: "success",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                }).then((result) => {
                  userAttributeContext.updateAttributeData(clientID);
                  result.isConfirmed && setWindowToDisplay("upload");
                });
              } else if (csvUploadResponse === "success waiting") {
                Swal.fire({
                  title: "Large File Received",
                  text: "New Attributes are being imported. Please check back later",
                  icon: "success",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                }).then((result) => {
                  result.isConfirmed && setWindowToDisplay("upload");
                });
              } else {
                Swal.fire({
                  title: "CSV Import Error",
                  text: "Image Attributes could not be imported",
                  icon: "error",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                }).then((result) => {
                  result.isConfirmed && setWindowToDisplay("upload");
                });
              }
            } else {
              if (csvUploadResponse === 406) {
                Swal.fire({
                  title: "CSV Import Error",
                  text: "Image ID is not present in first column for all images",
                  icon: "error",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                }).then((result) => {
                  result.isConfirmed && setWindowToDisplay("upload");
                });
              } else if (csvUploadResponse === 400) {
                Swal.fire({
                  title: "CSV Import Error",
                  text: "CSV Data could not be parsed",
                  icon: "error",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                }).then((result) => {
                  result.isConfirmed && setWindowToDisplay("upload");
                });
              }
            }
          } else if (bodyData.length === 0 && inValidRows.length > 0) {
            Swal.fire({
              title: "File Import Error",
              text: "No valid rows found in the file",
              icon: "error",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
            });
          }
        } else {
          Swal.fire({
            title: "CSV format error",
            text: "File does not have 'ID' as column for first row",
            icon: "error",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
          });
        }
        resetFileInput();
      };

      reader.onerror = (error) => {
        console.error("FileReader error:", error);
      };

      if (
        file.type === "text/csv" ||
        file.type === "application/vnd.ms-excel"
      ) {
        reader.readAsText(file); // Read CSV as text
      } else if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        reader.readAsArrayBuffer(file); // Read Excel files as ArrayBuffer
      } else {
        Swal.fire({
          title: "Unsupported file format",
          text: "Please upload a CSV or Excel file",
          icon: "error",
        });
        resetFileInput();
      }
    } else {
      Swal.fire({
        title: "No file selected",
        text: `${
          clientID ? "Missing ClientID" : "Please select a file to upload"
        }`,
        icon: "error",
      });
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // Determine delimiter used in a line (first to appear)
  const detectDelimiter = (line: string): string => {
    if (line.includes(",")) {
      return ",";
    } else if (line.includes(";")) {
      return ";";
    } else if (line.includes("\t")) {
      return "\t";
    } else {
      return ",";
    }
  };

  // Convert excel worksheet to CSV String
  const worksheetToCSV = (worksheet: ExcelJS.Worksheet): string => {
    const rows: string[] = [];
    worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      const cells: string[] = [];
      // Get the maximum number of columns in the worksheet, needed to manage empty cells
      const maxColumn = worksheet.columnCount;

      for (let colNumber = 1; colNumber <= maxColumn; colNumber++) {
        const cell = row.getCell(colNumber);
        let cellValue = cell.text || ""; // Default to empty string if cell is empty
        if (cellValue.includes(",")) {
          cellValue = `"${cellValue}"`;
        }
        cells.push(cellValue);
      }
      rows.push(cells.join(","));
    });
    return rows.join("\n");
  };

  return selectedClient === "loading" ? (
    <h3>Loading...</h3>
  ) : !selectedClient ? (
    <h3>No Client with ID {clientID} found</h3>
  ) : (
    <div className="import-wsc">
      <div
        id="upload_image_page_component_container"
        className="uk-flex uk-grid no_image form_container"
      >
        <div className="upload_form_container">
          <form id="upload_form">
            <fieldset className="uk-fieldset">
              <div className="upload_form_title">Manage Attributes</div>
              <EditIcon className="upload_icon" />
              <div id="upload_form_submit_container">
                <label
                  className="upload_form_submit_label"
                  onClick={(e) => setWindowToDisplay("wscEdit")}
                >
                  Manage
                </label>
              </div>
            </fieldset>
          </form>
        </div>
        <div className="upload_form_container">
          <form id="upload_form">
            <fieldset className="uk-fieldset">
              <div className="upload_form_title">
                {selectedClient.wsc
                  ? "Re-import Images from WSC"
                  : "Re-import Auto board Images"}
              </div>
              <UploadIcon className="upload_icon" />
              <div id="upload_form_submit_container">
                <label
                  className="upload_form_submit_label"
                  onClick={(e) => openReimportModal()}
                >
                  Re-import
                </label>
              </div>
            </fieldset>
          </form>
        </div>
        <div className="upload_form_container">
          <form id="upload_form">
            <fieldset className="uk-fieldset">
              <div className="upload_form_title">Import Attributes CSV</div>
              <ImportIcon className="upload_icon" />
              <div id="upload_form_submit_container">
                <label
                  className="upload_form_submit_label"
                  onClick={displayCsvInfo}
                >
                  Import
                </label>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".csv,.xlsx,.xls"
                  onChange={handleFileChange}
                />
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WSCImports;
