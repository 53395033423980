// TODO: BACK BUTTON ON REGISTRATION FORM
// TODO: CLASSNAMES

import { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import UIkit from "uikit";
import { ReactComponent as XMarkIcon } from "../../assets/images/icons/x-solid.svg";

import countryData from "../../assets/data/countries.json";
import languageData from "../../assets/data/languages.json";
import timezoneData from "../../assets/data/timezones.json";
import MDIKoala from "../../assets/images/koala/KoalaTemp.webp";
import MDILogo from "../../assets/images/logo/mdi-logo-final.webp";
import "./pending.scss";

interface FormData {
  name: string;
  surname: string;
  email: string;
  country_code: string;
  mobile: string;
  country: string;
  timezone: string;
  offset: string;
  org_unit: string;
  language: string;
  iso_code: string;
  client: string;
  sim_use: string;
}

function PendingPage() {
  //use param to check if pending submission or registration
  // const { submitted } = useParams();
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  // Get the location state from the previous page, to check if the user has already submitted
  const location = useLocation();
  const hasSubmitted = location.state && location.state.hasSubmitted;

  const existingEmailCheck = location?.state?.pending_email || "";

  // State to check country dropdown clicked
  const [countryClicked, setCountryClicked] = useState(false);
  const sortedCountries = countryData.countries
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  // Store the form data in state
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    country_code: "",
    mobile: "",
    country: "",
    timezone: "",
    offset: "",
    org_unit: "",
    language: "",
    iso_code: "",
    client: "",
    sim_use: "",
  });

  // Using useRef to store the latest formData
  const formDataRef = useRef(formData);

  // Update the formDataRef when formData changes
  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);

  // Check if the user has already submitted, by checking local storage
  const pendingUserEmail = localStorage.getItem("pending_user_email");

  // State for error checking inputs
  const [errorCheck, setErrorCheck] = useState(false);

  // Confirmation Modal ref
  const modalRef = useRef(null);

  // Input Ref for Mobile Number
  const inputRef = useRef<HTMLInputElement>(null);
  const [formattedMobile, setFormattedMobile] = useState("");

  // Check if the form has been submitted, so error checking is not prematurely triggered
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Error messages
  const [errorMessage, setErrorMessage] = useState("");
  const setError = (message: string) => {
    setErrorMessage(message);
  };

  // ============= Mobile Number Formatting ============= //
  const handleMobileChange = (e: { target: { value: any } }) => {
    const numericMobile = e.target.value.replace(/\D/g, "");

    let formattedValue = "";

    // Spacing in input 2/3/4
    if (numericMobile.length <= 2) {
      formattedValue = numericMobile;
    } else if (numericMobile.length <= 5) {
      formattedValue = `${numericMobile.slice(0, 2)} ${numericMobile.slice(2)}`;
    } else {
      formattedValue = `${numericMobile.slice(0, 2)} ${numericMobile.slice(
        2,
        5
      )} ${numericMobile.slice(5, 9)}`;
    }

    // What will be displayed in the input/modal
    setFormattedMobile(formattedValue);

    // What will be passed to DB
    const finalMobile = formattedValue.replace(/\s/g, "");
    setFormData({
      ...formData,
      mobile: finalMobile,
    });
  };

  // ============= Validation ============= //
  const isValidEmail = (email: string) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex and return the result
    return emailRegex.test(email);
  };
  const isValidMobile = (mobile: string) => {
    // Remove all spaces from the mobile number
    const mobileWithoutSpaces = mobile.replace(/\s/g, "");

    // Regular expression to validate mobile number length and format
    const mobileRegex = /^\d{9}$/; // Example: 10-digit number

    // Test the mobile number against the regex and return the result
    return mobileRegex.test(mobileWithoutSpaces);
  };

  // Submit Button (Not Confirmation), contains error checking
  const handleFormSubmit = () => {
    // Checks inputs for errors (empty or invalid)
    setErrorCheck(true);

    // Set form submitted to true
    setFormSubmitted(true);

    // Check if any of the form data is empty, or invalid
    const hasErrors = Object.entries(formData).some(([key, value]) => {
      if (value === "") {
        console.log(key, value, "Field is empty");
        setErrorMessage("*Please fill in all fields");
        return true; // Field is empty
      }

      if (key === "email" && !isValidEmail(value)) {
        console.log("Invalid email format");
        // Erro
        setErrorMessage("*Invalid email format");

        return true; // Invalid email format
      }

      if (key === "mobile" && !isValidMobile(value)) {
        console.log("Invalid mobile number");
        setErrorMessage("*Invalid mobile format");
        return true; // Invalid mobile number length
      }

      // Add more validation checks for other fields if needed

      return false; // No errors for this field
    });

    // console.log("Has errors:", hasErrors);

    if (modalRef.current && !hasErrors) {
      setErrorMessage("");
      UIkit.modal(modalRef.current).show();
    }

    // // For testing, no validation.
    // if (modalRef.current) {
    //   UIkit.modal(modalRef.current).show();
    // }
  };

  // Final Modal Confirmation Event, I'm sorry for this, UIKIT hijacks all events.
  useEffect(() => {
    let confirmationHandled = false;
    // Define the event listener function
    const handleConfirmationButtonClick = (e: {
      preventDefault: () => void;
      stopPropagation: () => void;
    }) => {
      e.preventDefault();
      e.stopPropagation();

      // Trigger handleConfirmation only if not handled before
      if (!confirmationHandled) {
        handleConfirmation(formDataRef.current);
        confirmationHandled = true;
      }
    };

    // Add the event listener when the component mounts
    const modalConfirmButton = document.getElementById(
      "final-modal-confirmation"
    );
    if (modalConfirmButton) {
      modalConfirmButton.addEventListener(
        "click",
        handleConfirmationButtonClick
      );
    }

    // Remove the event listener when the component unmounts
    return () => {
      if (modalConfirmButton) {
        modalConfirmButton.removeEventListener(
          "click",
          handleConfirmationButtonClick
        );
      }
    };
  }, []);

  const handleConfirmation = (formData: FormData) => {
    try {
      localStorage.setItem("pending_user_email", formData.email);
      console.log(
        "Email set in local storage:",
        localStorage.getItem("pending_user_email")
      );
      console.log("Form Data In handle confirmation:", formData);
      // close the modal
      if (modalRef.current) {
        UIkit.modal(modalRef.current).hide();
      }
      setSubmitted(true);
    } catch (error) {
      // console.error("Error setting email in local storage:", error);
    }
  };

  // Use effect that logs formData every time it is changed
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  //If the user has already submitted, checking hasSubmitted, set submitted to true
  useEffect(() => {
    if (hasSubmitted) {
      setSubmitted(true);
    }
  }, [hasSubmitted]);

  function handleBack() {
    navigate("/login");
  }

  return (
    <div id="page_pending">
      <img id="mdi_logo" src={MDILogo} alt="MDI Logo" />
      <div className="uk-container uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
        <div
          id="pending_card"
          className="uk-card uk-card-default uk-width-2xlarge uk-box-shadow-large"
        >
          <div className="uk-card-body outer_card">
            <div>
              <h3 className="smallHeaderFont uk-card-title uk-text-center">
                {submitted ? "Pending Submission" : "Registration"}
              </h3>

              {/* User has already submitted, (Looking at pending table, not in user table) */}
              {submitted ? (
                <>
                  <div className="uk-margin login_input_section">
                    <div className="uk-text-center">
                      Your application has been submitted successfully.
                    </div>
                  </div>
                  <div className="uk-flex uk-flex-center uk-flex-middle">
                    <button
                      className="uk-button btn_primary login_btn"
                      onClick={handleBack}
                    >
                      BACK
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {/* User has not submitted yet, (Not in pending or user table) */}
                  <div className="uk-card uk-card-body uk-padding-remove">
                    {/* Container */}
                    <div className="uk-grid uk-width-expand reg_container">
                      {/*============== Left Side ==============*/}
                      <div
                        className="uk-width-1-3 left_container"
                        style={{
                          backgroundImage: `url(${MDIKoala})`,
                        }}
                      ></div>

                      {/*============== Middle ==============*/}
                      {/* <div className="uk-width-1-6 middle_gutter"></div> */}

                      {/*============== Right Side ==============*/}
                      <form className="uk-grid uk-width-expand uk-grid-small reg_form">
                        {/* Name */}
                        <div className="uk-width-1-2 uk-margin-small-bottom">
                          <input
                            className={`uk-input ${
                              errorCheck && formData.name === ""
                                ? "invalid-input"
                                : ""
                            }`}
                            type="text"
                            placeholder="Name"
                            aria-label="Name"
                            value={formData.name}
                            maxLength={50}
                            onChange={(e) =>
                              setFormData({ ...formData, name: e.target.value })
                            }
                          ></input>
                        </div>

                        {/* Surname */}
                        <div className="uk-width-1-2 uk-margin-small-bottom">
                          <input
                            className={`uk-input ${
                              errorCheck && formData.surname === ""
                                ? "invalid-input"
                                : ""
                            }`}
                            type="text"
                            placeholder="Surname"
                            aria-label="Surname"
                            value={formData.surname}
                            maxLength={50}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                surname: e.target.value,
                              })
                            }
                          ></input>
                        </div>

                        {/* Email */}
                        <div className="uk-width-1-1 uk-margin-small-bottom">
                          <input
                            className={`uk-input ${
                              (formSubmitted &&
                                !isValidEmail(formData.email)) ||
                              (errorCheck && formData.email === "")
                                ? "invalid-input"
                                : ""
                            }`}
                            type="email"
                            placeholder="Email"
                            aria-label="Email"
                            value={formData.email}
                            maxLength={100}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                          ></input>
                        </div>

                        {/* Mobile Container */}
                        <div className="uk-width-1-1 uk-margin-small-bottom mobile_container">
                          {/* Country Code */}
                          <div className="uk-width-1-4 cc_selector">
                            <select
                              className={`selection ${
                                errorCheck &&
                                formData.country_code === "" &&
                                "invalid-input"
                              } ${
                                formData.country_code !== "" ? "selected" : ""
                              }`}
                              value={formData.country_code}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  country_code: e.target.value,
                                })
                              }
                            >
                              {/* <option value="">Select Country</option> */}
                              {/* Map the country codes from countryData */}
                              <option value="" className="default_selection">
                                (+0)
                              </option>
                              {sortedCountries.map((country) => (
                                <option
                                  key={`cc_${country._id}`}
                                  value={`${country.country_code}`}
                                >
                                  ({country.country_code})
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* Mobile Number */}
                          <div className="uk-width-expand">
                            <input
                              className={`uk-input ${
                                (formSubmitted &&
                                  !isValidMobile(formattedMobile)) ||
                                (errorCheck && formattedMobile === "")
                                  ? "invalid-input"
                                  : ""
                              }`}
                              type="text"
                              placeholder="Mobile Number"
                              aria-label="Mobile Number"
                              value={formattedMobile}
                              ref={inputRef}
                              maxLength={11}
                              onChange={handleMobileChange}
                            ></input>
                          </div>
                        </div>

                        {/* Country */}
                        <div className="uk-width-1-2 uk-margin-small-bottom">
                          <div className="uk-inline uk-width-expand">
                            {/* Country Selection */}
                            <select
                              className={`selection ${
                                errorCheck &&
                                formData.country === "" &&
                                "invalid-input"
                              } ${formData.country !== "" ? "selected" : ""}`}
                              value={formData.country}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  country: e.target.value,
                                })
                              }
                            >
                              <option value="" className="default_selection">
                                Select Country
                              </option>
                              {/* Map the country names from countryData */}
                              {sortedCountries.map((country) => (
                                <option key={country._id} value={country.name}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* Timezone */}
                        <div className="uk-width-1-2 uk-margin-small-bottom">
                          <div className="uk-inline">
                            {/* Timezone Selection */}
                            <select
                              className={`selection ${
                                errorCheck &&
                                formData.timezone === "" &&
                                "invalid-input"
                              } ${formData.timezone !== "" ? "selected" : ""}`}
                              value={formData.timezone}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  timezone: e.target.value,
                                  offset:
                                    e.target.options[
                                      e.target.selectedIndex
                                    ]?.getAttribute("data-offset") || "",
                                })
                              }
                            >
                              <option value="" className="default_selection">
                                Select Timezone
                              </option>
                              {/* Map the country names from countryData */}
                              {timezoneData.timezones.map((timezone) => (
                                <option
                                  key={timezone.id}
                                  value={timezone.id}
                                  data-offset={timezone.offset}
                                >
                                  {timezone.name} (
                                  {timezone.offset > 0 ? "+" : ""}
                                  {timezone.offset})
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* Language */}
                        <div className="uk-width-1-2 uk-margin-small-bottom">
                          <select
                            className={`selection ${
                              errorCheck &&
                              formData.language === "" &&
                              "invalid-input"
                            } ${formData.language !== "" ? "selected" : ""}`}
                            value={formData.language}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                language: e.target.value,
                                iso_code:
                                  e.target.options[
                                    e.target.selectedIndex
                                  ]?.getAttribute("data-iso-code") || "",
                              })
                            }
                          >
                            <option value="" className="default_selection">
                              Select Language
                            </option>
                            {/* Map the country names from countryData */}
                            {languageData.languages.map((language) => (
                              <option
                                key={language._id}
                                value={language.name}
                                data-iso-code={language.iso_code}
                              >
                                {language.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Org Unit */}
                        <div className="uk-width-1-2 uk-margin-small-bottom">
                          <input
                            className={`uk-input ${
                              errorCheck && formData.org_unit === ""
                                ? "invalid-input"
                                : ""
                            }`}
                            type="text"
                            placeholder="Org Unit"
                            aria-label="org_unit"
                            value={formData.org_unit}
                            maxLength={50}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                org_unit: e.target.value,
                              })
                            }
                          ></input>
                        </div>

                        {/* Client */}
                        <div className="uk-width-1-1 uk-margin-small-bottom">
                          <input
                            className={`uk-input ${
                              errorCheck && formData.client === ""
                                ? "invalid-input"
                                : ""
                            }`}
                            type="text"
                            placeholder="Projects"
                            aria-label="Client"
                            value={formData.client}
                            maxLength={50}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                client: e.target.value,
                              })
                            }
                          ></input>
                        </div>

                        {/* SIM Usage */}
                        <div className="uk-width-1-1 uk-margin-small-bottom">
                          <label className="uk-form-label uk-width-1-1 sim_use_label">
                            What will you use the SIM system for?{" "}
                          </label>
                          <textarea
                            className={`uk-textarea sim_use_textarea ${
                              errorCheck && formData.sim_use === ""
                                ? "invalid-input"
                                : ""
                            }`}
                            placeholder="E.g viewing client images"
                            aria-label="sim_use"
                            value={formData.sim_use}
                            maxLength={255}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                sim_use: e.target.value,
                              })
                            }
                          ></textarea>
                        </div>

                        {/* Submit & Error Message*/}
                        <div className="uk-width-1-1 uk-margin-small-bottom sub_error_container">
                          <div className=" uk-width-1-2 uk-margin-small-bottom error_container">
                            <span
                              className={`span_incorrect  ${
                                errorMessage ? `shown` : `hidden`
                              }`}
                            >
                              {errorMessage}
                            </span>
                          </div>
                          <div className="uk-width-1-2 uk-margin-small-bottom btn_container">
                            <button
                              type="button"
                              className="uk-button btn_primary login_btn"
                              onClick={() => handleFormSubmit()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ============== Confirmation Modal ============== */}
      <div id="confirmation-modal" uk-modal="true" ref={modalRef}>
        <div className="uk-modal-dialog uk-modal-body">
          <div className="modal_header">
            <h2 className="uk-modal-title uk-margin-remove-bottom">
              Please confirm your details
            </h2>
            <div className="iconsNavMenu uk-modal-close">
              <XMarkIcon className="iconSizesNav" />
            </div>
          </div>
          <hr />
          <div
            className="uk-grid-small uk-child-width-expand@s uk-text-center modal_details_container"
            uk-grid="true"
          >
            {/* MODAL BODY */}
            <div className="uk-card uk-card-body uk-width-1-1 modal_details">
              {/* Name */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">Name:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formData.name}
                  </div>
                </div>
              </div>

              {/* Surname */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">Surname:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formData.surname}
                  </div>
                </div>
              </div>

              {/* Email */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">Email:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formData.email}
                  </div>
                </div>
              </div>

              {/* Country Code */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">Country Code:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formData.country_code}
                  </div>
                </div>
              </div>

              {/* Mobile Number */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">Mobile Number:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formattedMobile}
                  </div>
                </div>
              </div>

              {/* Country */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">Country:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formData.country}
                  </div>
                </div>
              </div>

              {/* Timezone */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">Timezone:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formData.timezone +
                      " (" +
                      (Number(formData.offset) > 0 ? "+" : "") +
                      formData.offset +
                      ")"}
                  </div>
                </div>
              </div>

              {/* Language */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">Language:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formData.language}
                  </div>
                </div>
              </div>

              {/* Org Unit */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">Org Unit:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formData.org_unit}
                  </div>
                </div>
              </div>

              {/* Client */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">Projects:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formData.client}
                  </div>
                </div>
              </div>

              {/* Sim Use */}
              <div className="modal_detail_single uk-width-1-1 uk-margin-small-bottom">
                <div className="uk-width-1-3 modal_label">System Use:</div>
                <div className="uk-width-expand">
                  <div className="modal_confirmation_details">
                    {formData.sim_use}
                  </div>
                </div>
              </div>

              {/* Submit */}
              <div className="uk-width-1-1 uk-margin-small-bottom btn_container">
                <div>
                  <button
                    type="button"
                    id="final-modal-confirmation"
                    className="uk-button btn_primary confirm_btn"
                    // uk-toggle="target: #confirmation-modal"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingPage;
