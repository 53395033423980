import localforage from "localforage";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import UIkit from "uikit"; // imported for slider
import jsonImageVersionData from "../../assets/data/photosVersions.json";
import { ReactComponent as BackIcon } from "../../assets/images/icons/back.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/icons/download-solid.svg";
import { ReactComponent as EditIcon } from "../../assets/images/icons/editImage.svg";
import { ReactComponent as FileDownload } from "../../assets/images/icons/fileDownload.svg";
import { ReactComponent as SaveIconDark } from "../../assets/images/icons/floppy-disk-solid-dark.svg";
import { ReactComponent as MagnifyIcon } from "../../assets/images/icons/magnifying-glass-plus-solid.svg";
import { ReactComponent as Rotate } from "../../assets/images/icons/rotate-right-solid.svg";
import { ReactComponent as AssignUserAttribute } from "../../assets/images/icons/square_plus.svg";
import { ReactComponent as DeleteIconWhite } from "../../assets/images/icons/trash-solid-white.svg";
import { ReactComponent as XIcon } from "../../assets/images/icons/x-solid.svg";
import { useAttributeContext } from "../../context/UserAttributeContext";
import {
  deleteImage,
  fetchCsvDocument,
  removeImagesFromBoard,
  updateImageData,
} from "../../models/images.model";
import { assignAttributes } from "../../models/wsc.model";
import { ImageType, ManageAttributes } from "../../types";
import ImageLoader from "../ImageLoader/ImageLoader.component";
import ImageAttributes from "./imageAttributes.component";
import "./imageAttributes.scss";
import "./singleImage.scss";

type ImageProps = {
  singleImage: ImageType | undefined;
  // for bulk actions
  setShowBulk: (showBulk: boolean) => void;
  showBulk: boolean;
  selectedBulkImageIds: string[]; // Added prop for selectedBulkImageIds
  setBulkSelectedImageIds: (imageIds: string[]) => void;
  selectedImageIds: string[];
  setSelectedImageIds: React.Dispatch<React.SetStateAction<string[]>>;
  setSingleImageID: (image: string | null) => void;
  setShowSingleImageModal: (showModal: boolean) => void;
  singleImageType?: string;
  sourceID: string;
};

const SingleImage: React.FC<ImageProps> = ({
  singleImage,
  // For bulk actions
  setShowBulk,
  showBulk,
  selectedBulkImageIds,
  setBulkSelectedImageIds,
  selectedImageIds,
  setSelectedImageIds,
  setSingleImageID,
  setShowSingleImageModal,
  singleImageType,
  sourceID,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const handleImageLoaded = () => {
    setIsLoaded(true);
  };
  const userAttributeContext = useAttributeContext();

  useEffect(() => {
    // initialize the slider and reset image data on reload of singleImage
    UIkit.slider(".uk-slider-container");
    setTitle(singleImage?.title);
    setDescription(singleImage?.description);
    setKeywords(singleImage?.keywords);
    setOCR(singleImage?.ocr);
    setSource(singleImage?.source);
  }, [singleImage]);

  const [imageEnlarged, setImageEnlarged] = useState(false);
  const [imageEdit, setImageEdit] = useState(false);
  const [imageDelete, setImageDelete] = useState(false);

  const renderTest = true;

  const navigate = useNavigate();

  // User Attributes
  const [userAttributes, setUserAttributes] = useState<ManageAttributes[]>([]);
  const [displayAssignAttributes, setDisplayAssignAttributes] =
    useState<Boolean>(false);
  const [attributeInputDisplayError, setAttributeInputDisplayError] =
    useState<Boolean>(false);
  const [displayAttributeErrorMessage, setDisplayAttributeMessage] =
    useState<string>();
  const [assigningAttributeDisplay, setAssigningAttributeDisplay] =
    useState<boolean>(false);
  const [attributeAssignmentDisplay, setAttributeAssignmentDisplay] =
    useState<string>();

  //formData, initialize with singleImage data
  const [imageTitle, setTitle] = useState(singleImage?.title);
  const [imageDescription, setDescription] = useState(singleImage?.description);
  const [imageKeywords, setKeywords] = useState(singleImage?.keywords);
  const [imageOCR, setOCR] = useState(singleImage?.ocr);
  const [imageSource, setSource] = useState(singleImage?.source);
  const [rotationDegree, setRotationDegree] = useState(0);

  useEffect(() => {
    if (singleImageType === "shared") {
      return;
    }
    const callAttributesRetrieval = async () => {
      const retrievedUserAttributes =
        await userAttributeContext.getAttributeData(sourceID);
      setUserAttributes(retrievedUserAttributes);
    };

    callAttributesRetrieval();
  }, [userAttributeContext.attributeData]);

  const handleEdit = () => {
    //display edit areas
    setImageEdit(true);
    setRotationDegree(0);
  };

  const handleRotateImage = () => {
    //rotate the image
    setRotationDegree((prev) => (prev + 90) % 360);
  };

  // -------------------------------------------------------
  // IMAGE DELETION OR REMOVAL FROM BOARD

  // Board ID not available in Client View
  const { boardID } = useParams<{ boardID: string }>();

  // Change state of Delete Image to Display Deletion Popup
  const handleDeleteOpen = () => {
    //display the delete popup
    setImageDelete(true);
  };

  // First Image Deletion?Removal Popup Window
  const openDeleteOrRemove = (imageID: string) => {
    // Type = 'client' -> Image Deletion; type = 'board' -> remove
    const titleString =
      singleImageType === "client"
        ? "Delete " + singleImage?.title
        : "Remove " + singleImage?.title + " from Board";
    Swal.fire({
      title: titleString,
      text:
        "Are you sure you want to " +
        (singleImageType === "client"
          ? "delete this image?"
          : "remove this image?"),
      icon: "warning",
      iconColor: "#BB2D3B",
      showCancelButton: true,
      confirmButtonColor: "#BB2D3B",
      cancelButtonColor: "#3085d6",
      confirmButtonText:
        singleImageType === "client" ? "Yes, delete it!" : "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        imagePopupConfirmation(imageID);
      }
    });
  };

  // Image Delete or Removal Confirmation
  const imagePopupConfirmation = (imageID: string) => {
    // Type = 'client' -> Image Deletion; type = 'board' -> remove
    Swal.fire({
      title:
        singleImageType === "client"
          ? "Deletion Confirmation"
          : "Removal Confirmation",
      text:
        "Please type " +
        (singleImageType === "client" ? "DELETE" : "REMOVE") +
        " to confirm:",
      input: "text",
      inputAttributes: {
        autocomplete: "off",
      },
      confirmButtonText:
        singleImageType === "client" ? "Confirm Deletion" : "Confirm Removal",
      confirmButtonColor: "#BB2D3B",
      showCloseButton: true,
      cancelButtonText: "Cancel",
      showCancelButton: true,
      icon: "warning",
      iconColor: "#BB2D3B",
      showConfirmButton: true,
      inputValidator(value) {
        if (singleImageType === "client") {
          if (!value) {
            return "Please type 'DELETE' to confirm";
          }
          if (value !== "DELETE") {
            return "Please type 'DELETE' to confirm";
          }
        } else if (singleImageType === "board") {
          if (!value) {
            return "Please type 'REMOVE' to confirm";
          }
          if (value !== "REMOVE") {
            return "Please type 'REMOVE' to confirm";
          }
        } else {
          console.log(
            "Unknown Image Source Type Received for Deletion/Removal"
          );
        }
      },
    }).then((result): void => {
      if (result.isConfirmed) {
        Swal.fire({
          title: singleImageType === "client" ? "Deleting..." : "Removing...",
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        handleDeleteRemoveSubmit(imageID);
      }
    });
  };

  // Request Deletion of Image
  const handleDeleteRemoveSubmit = async (imageID: string) => {
    //if deleteImage is true, delete/remove the image and go to dashboard
    //else close popup
    if (imageDelete) {
      if (singleImageType === "client") {
        await handleDelete(imageID);
      } else if (singleImageType === "board" && boardID) {
        await handleImageRemoval(boardID, imageID);
      } else {
        return;
      }
    } else {
      return;
    }
    setImageDelete(false);
    window.location.reload();
    navigate(`/dashboard/`);
  };

  // Perform Image Deletion from Client
  const handleDelete = async (imageID: string) => {
    let response = await deleteImage(sourceID, imageID);
    if (response === "success") {
      Swal.fire({
        icon: "success",
        title: "Image Deleted",
        showConfirmButton: false,
        confirmButtonColor: "#3085d6",
      });
      // Clear thumbnails
      try {
        await localforage.removeItem(`ds-client-${sourceID}-current`);
        await localforage.removeItem(`ds-client-${sourceID}-new`);

        const boardIDs = await getBoardsForImageRemoval();
        if (boardIDs) {
          const removalPromises = boardIDs.map((board: any) =>
            Promise.all([
              localforage.removeItem(`ds-board-${board.id}-current`),
              localforage.removeItem(`ds-board-${board.id}-new`),
            ]).catch((error) =>
              console.error("Error removing board items:", error)
            )
          );

          await Promise.all(removalPromises);
        }
      } catch (error) {
        console.error(
          `Error during cache cleanup for client ${sourceID} or boards during image deletion:`,
          error
        );
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to delete image",
        text: response,
        confirmButtonColor: "#3085d6",
      });
    }
  };

  const getBoardsForImageRemoval = async () => {
    try {
      console.log("CALLING BOARD IMAGE REMOVAL");
      const allBoardsData = await localforage.getItem("allBoards");
      console.log("---ALL ----", allBoardsData);
      if (allBoardsData && typeof allBoardsData === "string") {
        const boards = JSON.parse(allBoardsData).boards;
        if (boards) {
          const matchingBoards = boards.filter(
            (board: any) => board.clientID === sourceID
          );
          return matchingBoards;
        }
        return null;
      }
    } catch (error) {
      console.error("Error in getBoardsData:", error);
    }
  };

  // Perform Image Removal From Board
  // If removal request from client, do not display Swal alerts or reload window after each
  // image removal from board
  const handleImageRemoval = async (boardID: string, imageID: string) => {
    if (boardID) {
      const removeImageResponse = await removeImagesFromBoard({
        boardID: boardID,
        imagesToRemove: [imageID], // replace with actual image identifiers
      });
      if (removeImageResponse.rStatus === "success") {
        Swal.fire({
          icon: "success",
          title: "Image Removed",
          showConfirmButton: false,
          confirmButtonColor: "#3085d6",
        });
        // Clear thumbnails
        try {
          //clear Cache for the page
          //Remove Local Forage Cache for Client:
          localforage.removeItem(`ds-board-${boardID}-current`);
          localforage.removeItem(`ds-board-${boardID}-new`);
        } catch (error) {
          console.log(
            "Error removing board from localforage during Image removal from board",
            error
          );
        }
        // reload the page
        if (!imageDelete) {
          window.location.reload();
        }
      } else {
        console.log("ERROR REMOVING", removeImageResponse.rMessage);
        console.error("Error removing Image From Board: ");
        console.error(
          "Status: ",
          removeImageResponse.rStatus,
          " Message: ",
          removeImageResponse.rMessage
        );
        Swal.fire({
          icon: "error",
          title: "Failed to remove image",
          text: removeImageResponse.rStatus,
          confirmButtonColor: "#3085d6",
        });
      }
    } else {
      console.error("Missing Board ID for single image removal");
    }
  };

  const handleEditSubmit = async (submit: boolean) => {
    if (submit) {
      //Check for validation of keywords if they exist
      if (imageKeywords && imageKeywords.length > 0) {
        //Split the keywords by comma and remove any whitespace, if there  is a comma if not just remove whitespace
        let allKeywords = imageKeywords
          .map((keyword) =>
            keyword.includes(",")
              ? keyword.split(",").map((word) => word.trim())
              : keyword.trim()
          )
          .flat();
        //Flatten the array if there are commas
        // Function to show toast warnings
        const showToastWarning = (message: string) => {
          const ToastWarning = Swal.mixin({
            toast: true,
            position: "top",
            showConfirmButton: false,
            timer: 3000,
            showCloseButton: true,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          ToastWarning.fire({
            icon: "error",
            title: message,
          });
        };

        for (let i = 0; i < allKeywords.length; i++) {
          const keyword = allKeywords[i];

          // Type guard to ensure keyword is a string
          if (typeof keyword === "string") {
            // More than 20 characters
            if (keyword.length > 20) {
              // We only want the first 10 characters to be displayed
              let subString = keyword.substring(0, 10);
              showToastWarning(
                `Keyword "${subString}.." should be less than 20 characters`
              );
              return;
            } else if (keyword.length < 2) {
              // Keyword is too short
              showToastWarning(
                `Keyword "${keyword}" should be at least 2 characters long`
              );
              return;
            }

            // Check for special characters ()-&@*$|%~
            let regex = /[()&@*$|%~]/;
            if (regex.test(keyword)) {
              showToastWarning(
                `Keyword should not contain any special characters`
              );
              return;
            }
          } else {
            // Handle the unexpected case where keyword is not a string
            console.error("Keyword is not a string:", keyword);
          }
        }
      }
      const response = await updateImageData({
        clientID: sourceID,
        id: singleImage?.id || "",
        title: imageTitle || "",
        description: imageDescription || "",
        keywords: imageKeywords || [],
        ocr: imageOCR || "",
        url: imageSource || "",
        rotate: rotationDegree.toString() || null,
      });

      if (response.rStatus === "error") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.rMessage,
          confirmButtonColor: "#3085d6",
        });
        return;
      }

      // Clear thumbnails
      try {
        caches.delete("pwabuilder-page").then((response) => {
          if (response) {
            console.log(`Cache 'pwabuilder-page' deleted`);
          } else {
            console.log(`Cache 'pwabuilder-page' not found`);
          }
        });
      } catch (error) {
        console.log(error);
      }

      // reload the page
      window.location.reload();
    } else {
      setTitle(singleImage?.title);
      setDescription(singleImage?.description);
      setKeywords(singleImage?.keywords);
      setOCR(singleImage?.ocr);
    }
    setImageEdit(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  // TODO: Download image FIX We can't download images from external sources due to CORS policy,
  const handleDownload = async () => {
    try {
      if (!singleImage?.download) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Download link not found",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
      fetch(singleImage?.download)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          }
          throw new Error("Network response was not ok.");
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = singleImage.title + ".png"; // Optionally, set a filename here
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    } catch (error) {
      console.log("error", error);
      console.log("Error downloading image:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleEnlarge = (display: boolean) => {
    //display or hide the full page image
    setImageEnlarged(display);
  };

  // console.log(singleImage?.width, singleImage?.height);
  const ratio =
    // singleImage?.width && singleImage?.height
    //   ? singleImage?.width / singleImage?.height
    //   : 1;
    1;
  let orientation = "";
  if (ratio >= 1.3) {
    orientation = "landscape";
  } else if (ratio <= 0.8) {
    orientation = "portrait";
  } else {
    orientation = "square";
  }
  // console.log(ratio, orientation);

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const [imageVersion, setImageVersion] = useState("");
  const [imageVersionUpdatedAt, setImageVersionUpdatedAt] = useState("");

  const handleHistory = (versionID: string) => {
    setImageVersion(versionID);
    //find the version in the json file and update the singleImage
    const foundVersion = jsonImageVersionData.images
      .flatMap((image) => image.ImageData)
      .find((version) => version.versionID === versionID);
    if (foundVersion) {
      setImageVersion(versionID);
      setTitle(foundVersion.title);
      setDescription(foundVersion.description);
      setKeywords(foundVersion.keywords);
      setOCR(foundVersion.ocr);
      setImageVersionUpdatedAt(foundVersion.dateUpdated);
      setSource(foundVersion.source);

      const element = document.getElementById(
        "single_image_page_component_container"
      );
      if (element) {
        element.scrollTop = 0;
      }
    } else {
      //rest values
      setImageVersion("");
      setTitle(singleImage?.title);
      setDescription(singleImage?.description);
      setKeywords(singleImage?.keywords);
      setOCR(singleImage?.ocr);
      setImageVersionUpdatedAt("");
      setSource(singleImage?.source);
    }
  };

  const versionImageData = jsonImageVersionData.images.find(
    (image) => image.id === singleImage?.id
  )?.ImageData; //set the version data for the image
  /* //For debugging 
        const versionImageIDs = versionImageData?.map(image => image.versionID);
        console.log("versionImageIDs: " + versionImageIDs);
    */

  // when the component is loaded, scroll to the top of the page
  useEffect(() => {
    const element = document.getElementById(
      "single_image_page_component_container"
    );
    if (element) {
      element.scrollTop = 0;
    }
  }, [singleImage?.id]);

  const downloadAllData = async (clientID: string, imageID: string) => {
    Swal.fire({
      title: "Downloading data...",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (clientID && imageID) {
      const excelEmailResponse = await fetchCsvDocument(clientID, [imageID]);
      if (excelEmailResponse.statusCode === 202) {
        console.log("Excel Doc scheduled for email");

        Swal.fire({
          icon: "info",
          iconColor: "#183659",
          title: "Image Attribute Download",
          text: "Image attributes will be sent to your email shortly. You may close this popup.",
          allowOutsideClick: true,
          allowEscapeKey: true,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Close",
          cancelButtonColor: "#183659",
          // didOpen: () => {
          //   Swal.showLoading();
          // },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Download failed. Please try again later.",
          confirmButtonColor: "#3085d6",
        });
      }
    } else {
      console.error(
        "Missing clientID and/or selected image ID's for bulk image data download"
      );
      Swal.fire({
        title: "Download Error",
        icon: "error",
        iconColor: "#BB2D3B",
        text: "An error has occurred during image download",
        allowOutsideClick: false,
      });
    }
  };

  // Display or Remove Assign Attribute Sub-Window

  function toggleAttributeDisplay(toggleMode: boolean) {
    setDisplayAssignAttributes(toggleMode);
    setAttributeInputDisplayError(false);
  }

  const AttributeAssignPopup = (
    chosenAttribute: string,
    attributeValue: string | null
  ) => {
    const specialChars = ["(", ")", "&", "@", "$", "|", "%", "~"];

    if (!chosenAttribute) {
      setAttributeInputDisplayError(true);
      setDisplayAttributeMessage("Please select an Attribute Name");
    } else if (!attributeValue) {
      setAttributeInputDisplayError(true);
      setDisplayAttributeMessage("Please enter a value");
    } else if (attributeValue.trim() === "") {
      setAttributeInputDisplayError(true);
      setDisplayAttributeMessage("Value cannot be blank");
    } else if (attributeValue.trim().length < 3) {
      setAttributeInputDisplayError(true);
      setDisplayAttributeMessage("Value cannot be shorter than 3 characters");
    } else if (attributeValue.trim().length > 16) {
      setAttributeInputDisplayError(true);
      setDisplayAttributeMessage("Value cannot be longer than 16 characters");
    } else {
      let invalidValue = false;
      specialChars.map((char) => {
        if (attributeValue.includes(char)) {
          invalidValue = true;
          setAttributeInputDisplayError(true);
          setDisplayAttributeMessage("Value cannot contain special characters");
          return;
        } else {
        }
      });
      if (!invalidValue) {
        setAttributeInputDisplayError(false);
        setAssigningAttributeDisplay(true);
        setAttributeAssignmentDisplay("Assigning Attribute");
        assignUserAttributes(sourceID, chosenAttribute, attributeValue);
      }
    }
  };

  async function assignUserAttributes(
    clientId: string,
    attributeId: string,
    value: string
  ) {
    if (clientId && singleImage?.id) {
      console.log("IMAGE ID", singleImage?.id);
      try {
        console.time("Assigning User Attributes");
        const assignedAttributesResult = await assignAttributes(
          clientId,
          [singleImage?.id],
          attributeId,
          value
        );
        console.timeEnd("Assigning User Attributes");
        if (
          assignedAttributesResult &&
          assignedAttributesResult !== "success"
        ) {
          console.error("User Attributes not assigned");
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Attributes could not be assigned.",
          });
          return;
        } else {
          // Remove Spinner and 'assigning attribute' message
          setAssigningAttributeDisplay(false);
          Swal.fire({
            title: "Success",
            text: "Attributes successfully assigned",
            icon: "success",
          });
        }
      } catch (error) {
        console.error("Error, User Attributes not assigned", error);
      }
    }
  }

  return (
    <div
      id="single_image_page_component_container"
      ref={containerRef}
      className="uk-flex uk-grid"
    >
      {
        /* display message if version is not null */
        imageVersion !== "" ? (
          <div id="version_message">
            <p>
              An Old Version of the image is being displayed
              <br />
              Updated at: {imageVersionUpdatedAt}
              <br />
              <button onClick={() => handleHistory("")}>
                Back to latest version
              </button>
              <br />
              <br />
            </p>
          </div>
        ) : null
      }

      <div
        id="single_image_page_component_container_top"
        className="uk-flex uk-grid uk-width-1-1@s"
      >
        <div
          id="image_div"
          className={`${
            imageEdit
              ? "edit_image uk-width-1-1@s uk-width-1-1@m"
              : `uk-width-1-1@s uk-width-2-5@m img_orentation_${orientation}`
          }`}
        >
          {/* Render the ImageLoader component as a placeholder until the image is loaded */}
          {!isLoaded && <ImageLoader width="100%" height="19rem" />}
          <img
            src={`${imageSource}`}
            className={`img_orentation_${orientation}`}
            alt={singleImage?.title}
            onLoad={handleImageLoaded}
            style={{
              display: isLoaded ? "block" : "none",
              transform: `${
                imageEdit ? `rotate(${rotationDegree}deg)` : "none"
              }`,
            }}
          />
          {!imageEdit && (
            <div id="image_actions">
              {/* Hide edit and delete elements if viewing past versions */}
              {imageVersion === "" && singleImageType !== "shared" ? (
                <EditIcon
                  className="image_actions_icon first_child"
                  onClick={handleEdit}
                />
              ) : (
                ""
              )}
              {/* Image Deletion Moved to Image Hover Sidebar -> photo, */}
              {imageVersion === "" && singleImageType !== "shared" ? (
                <DeleteIconWhite
                  className="image_actions_icon"
                  onClick={handleDeleteOpen}
                />
              ) : (
                ""
              )}
              <MagnifyIcon
                className="image_actions_icon"
                onClick={() => handleEnlarge(true)}
              />
              <DownloadIcon
                className="image_actions_icon"
                onClick={handleDownload}
              />
              {singleImageType !== "shared" ? (
                <FileDownload
                  className="image_actions_icon"
                  onClick={() => {
                    downloadAllData(
                      sourceID,
                      singleImage?.id ? singleImage?.id : ""
                    );
                  }}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div
          id="image_info_div"
          className={`${
            imageEdit
              ? "edit_image_info uk-width-1-1@s uk-width-1-1@m"
              : "uk-width-1-1@s uk-width-3-5@m"
          }`}
        >
          {imageEdit && (
            <div id="image_actions_submit">
              <div className="submit_button_container">
                <button onClick={() => handleEditSubmit(true)}>
                  <SaveIconDark className="image_actions_icon" />
                  <span> Save</span>
                </button>
                <button onClick={() => handleEditSubmit(false)}>
                  <DeleteIconWhite className="image_actions_icon" />
                  <span> Cancel</span>
                </button>
              </div>
            </div>
          )}
          {!imageEdit ? (
            <h1>{imageTitle}</h1>
          ) : (
            <input
              id="input_edit_title"
              type="text"
              value={imageTitle}
              onChange={(e) => setTitle(e.target.value)}
            ></input>
          )}

          {/* {Removed Description} */}
          {/* <div id="image_desc_div">
            <h3>Description</h3>
            {!imageEdit ? (
              <p>{imageDescription}</p>
            ) : (
              <textarea
                value={imageDescription}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            )}
            // <div id="image_id_div">ID: {singleImage?.id}</div>
          </div> */}
          <div
            id="image_keywords_div"
            className="uk-width-1-1@s uk-width-1-1@m image_bottom_div"
          >
            <h3>Keywords</h3>

            {!imageEdit ? (
              <p>{imageKeywords ? imageKeywords.join(", ") : ""}</p>
            ) : (
              <>
                <p className="warning-description">
                  Each keyword can only be 2 to 20 characters long and should be
                  separated by a comma
                </p>
                <textarea
                  value={imageKeywords ? imageKeywords.join(", ") : ""}
                  onChange={(e) => setKeywords(e.target.value.split(", "))}
                ></textarea>
              </>
            )}
          </div>
        </div>

        {/* This is the image attributes container */}
        <div className="imageAttributesContainer uk-width-1-1@s uk-width-1-1@m">
          <div className="imageAttributesTitle">
            <h3>Image Attributes</h3>

            {singleImageType !== "shared" && (
              <AssignUserAttribute
                className="assignAttributeButton"
                onClick={() => {
                  displayAssignAttributes === true
                    ? toggleAttributeDisplay(false)
                    : toggleAttributeDisplay(true);
                }}
                data-toggle="tool-tip"
                title="Assign Attribute"
              />
            )}
          </div>
          <div>
            {displayAssignAttributes &&
            userAttributes.length > 0 &&
            singleImageType !== "shared" ? (
              <div>
                <div className="assignment_div">
                  <select name="selectedAttribute" id="selectedAttribute">
                    <option value="" disabled selected>
                      Available User Attributes
                    </option>
                    $
                    {userAttributes.map((attribute) => (
                      <option value={attribute.id}>{attribute.name}</option>
                    ))}
                  </select>
                  <input
                    id="value"
                    type="text"
                    placeholder="Enter Value to Assign"
                    required
                    className="black-placeholder"
                  ></input>
                  <button
                    id="assignAttributeButton"
                    onClick={() =>
                      AttributeAssignPopup(
                        (
                          document.getElementById(
                            "selectedAttribute"
                          ) as HTMLInputElement
                        ).value,
                        (document.getElementById("value") as HTMLInputElement)
                          .value
                      )
                    }
                  >
                    Assign
                  </button>
                </div>
                {/* Display Error for User Attribute Input */}
                {attributeInputDisplayError ? (
                  <div className="attributeError">
                    {displayAttributeErrorMessage}
                  </div>
                ) : (
                  <></>
                )}
                {/* Display Loading Text whilst assigning User Attribute*/}
                {assigningAttributeDisplay ? (
                  <div className="attributeAssignment">
                    <span
                      uk-spinner="ratio: 1"
                      style={{ marginRight: "10px" }}
                    ></span>
                    {attributeAssignmentDisplay}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <div>
                {displayAssignAttributes && userAttributes.length === 0 ? (
                  <div className="noUserAttributesContainer">
                    <div className="noUserAttributesContainer">
                      No user attributes found. Use the 'Manage Attributes' tab
                      to create new user attributes.
                    </div>

                    <button
                      className="ManageAttributesNavBtn"
                      onClick={() => navigate("/upload/" + sourceID)}
                    >
                      Go
                    </button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          <ImageAttributes
            clientID={sourceID ? sourceID : null}
            imageID={singleImage?.id ? singleImage.id : null}
            imageObject={
              singleImage?.attributes ? singleImage.attributes : null
            }
            userAttributes={userAttributes ? userAttributes : null}
          />
          {/* OCR */}
          <div
            id="image_ocr_div"
            className="uk-width-1-1@s uk-width-1-1@m image_bottom_div"
          >
            <h3>OCR</h3>
            {!imageEdit ? (
              <p>{imageOCR}</p>
            ) : (
              <textarea
                value={imageOCR}
                onChange={(e) => setOCR(e.target.value)}
              />
            )}
          </div>
        </div>
      </div>
      {
        /* Only display single_image_page_component_container_middle if there is a previous version and not editing */
        versionImageData && versionImageData.length > 0 && !imageEdit && (
          <div
            id="single_image_page_component_container_middle"
            className="uk-flex uk-grid uk-width-1-1@s"
          >
            <h2>Version History</h2>
            <div id="versions_slider" className="uk-slider-container">
              <div
                className="uk-position-relative uk-visible-toggle uk-light"
                tabIndex={-1}
              >
                <ul className="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@s uk-grid">
                  {versionImageData.map((version) => (
                    <li key={version.versionID}>
                      <div
                        className="uk-card"
                        onClick={() => handleHistory(version.versionID)}
                      >
                        <div className="uk-card-media-top">
                          <div
                            className="top_image"
                            style={{
                              backgroundImage: `url(${version.source})`,
                            }}
                          ></div>
                        </div>
                        <div className="uk-card-body">
                          <p>
                            <strong>{version.dateUpdated}</strong>
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
                <a
                  className="uk-position-center-left uk-position-small uk-hidden-hover"
                  href="#"
                  uk-slidenav-previous
                  uk-slider-item="previous"
                ></a>
                <a
                  className="uk-position-center-right uk-position-small uk-hidden-hover"
                  href="#"
                  uk-slidenav-next
                  uk-slider-item="next"
                ></a>
              </div>
              <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
            </div>
          </div>
        )
      }
      {/* {imageVersion === "" && !imageEdit ? (
        <div
          id="single_image_page_component_container_bottom"
          className="uk-width-1-1@s"
        >
          <h2 className="title">Related Images</h2>
          <>
            <PhotosContainer
              selectType="keyword"
              tagArray={singleImage?.keywords}
              setShowBulk={setShowBulk}
              showBulk={showBulk}
              selectedBulkImageIds={selectedBulkImageIds}
              setBulkSelectedImageIds={setBulkSelectedImageIds}
              selectedImageIds={selectedImageIds}
              setSelectedImageIds={setSelectedImageIds}
              setSingleImageID={setSingleImageID}
              setShowSingleImageModal={setShowSingleImageModal}
            />
          </>
        </div>
      ) : (
        <div className="uk-flex uk-grid uk-width-1-1@s"></div>
      )} */}
      {
        /* Back Icon to navigate to previous page */
        imageVersion === "" ? (
          <>
            <BackIcon
              id="back_icon"
              onClick={() => setShowSingleImageModal(false)}
            />
            {imageEdit && (
              <Rotate
                className="rotate_icon"
                onClick={() => handleRotateImage()}
                uk-tooltip="title: Rotate image, full image preserved; pos: right"
              />
            )}
          </>
        ) : (
          ""
        )
      }
      {
        /* display full page popup to display enlarged image */
        imageEnlarged && (
          <div id="enlarged_image">
            <img src={imageSource} alt={singleImage?.title} />
            {/* <span onClick={() => handleEnlarge(false)}>XIcon</span> */}
            <XIcon className="x_icon" onClick={() => handleEnlarge(false)} />
          </div>
        )
      }
      {
        /* display popup if image delete is clicked */
        imageDelete &&
          singleImage?.id &&
          (openDeleteOrRemove(singleImage?.id), null)
      }
    </div>
  );
};

export default SingleImage;
